import React, {useEffect, useState} from 'react'
import {Box, Card, CardContent, CardHeader, Tab, Tabs} from "@mui/material";
import {a11yProps, TabPanel} from '../../../components/Tabs';
import AdPictureUploadTab from "./Tabs/AdPictureUploadTab";
import {useParams} from "react-router";
import authClient from "../../../api/authClient";
import {getRoom} from "../../../router/api-routes";
import EditRoom from '../Rooms/edit/EditRoom';
import {Room} from "../../../models/Room";
import AdPortalsTab from "./Tabs/Portals/AdPortalsTab";
import RoomAmenities from "../Rooms/edit/RoomAmenities";


export default function AdEdit(){

    const [value, setValue]= useState(0);
    const params = useParams()
    const [room,setRoom] = useState<Room|undefined>();
    const getRoomHandler = ()=> authClient.get(getRoom(params.id)).then(response => {
        setRoom( new Room(response.data))
    })

    // @ts-ignore
    const title = room ? room.title : "";

    useEffect(()=> {
        getRoomHandler()
    },[])

    return <Card>
        <CardHeader title={title} subheader={room?.isEligibleForPublication() ? "Room is publishable" : "Room is not publishable"}/>
        <CardContent>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={(event, newValue)=>setValue(newValue)} aria-label="basic tabs example">
                    <Tab label="Edit" {...a11yProps(0)} />
                    <Tab label="Photos" {...a11yProps(1)} />
                    <Tab label="Portals" {...a11yProps(2)} />
                    <Tab label="Amenities" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {room && <EditRoom room={room}/>}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AdPictureUploadTab room={room} getRoom={getRoomHandler}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                {room && <AdPortalsTab room={room}/>}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {room && <RoomAmenities room={room}/>}
            </TabPanel>
        </CardContent>

    </Card>

}
