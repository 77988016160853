import React, {useEffect, useState} from 'react'
import {useFormik} from "formik";
import {Button, FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography} from "@mui/material";
import authClient from "../../../api/authClient";
import {createRoom, listings} from "../../../router/api-routes";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import * as Yup from 'yup';
import {useNavigate} from "react-router";
import {editAd} from "../../../router/frontend-routes";

interface SelectItem{
    id: string,
    label:string
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    roomType: Yup.string().required('Room type is required'),
    weeklyPrice: Yup.number().required('Weekly price is required').moreThan(0),
    latitude: Yup.number(),
    longitude: Yup.number(),
});

interface AdCreateInterface{
    property?: number;
}

export default function AdCreate({property}:AdCreateInterface){

    const [roomTypes, setRoomTypes] = useState<SelectItem[]>([])
    const getRoomTypes = ()=> authClient.post(listings, {resources: {"room_types":[]}})
    const navigate = useNavigate()

    useEffect(()=>{getRoomTypes().then(response => setRoomTypes(response.data.room_types))},[])

    const {values,setValues, errors,touched, handleChange,handleSubmit} = useFormik({
        initialValues: {
            title: '',
            roomType: undefined,
            description: "",
            video: "",
            view360:"",
            availableFrom:null,
            weeklyPrice:0,
            latitude: 0,
            longitude: 0,
            property: property
        },
        validationSchema,
        onSubmit: (values) => authClient.post(createRoom, values ).then((response)=>navigate(editAd(response.data.uuid)))
    });


    const handleDateValue = (newValue: Date | null) => {
        // @ts-ignore
        setValues({...values, availableFrom: newValue})
    };

    const handleSelectValue = (event:SelectChangeEvent) => {
        // @ts-ignore
        setValues({...values, roomType: event.target.value as string})
    };



    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{"mb":1}}>Title</Typography>
                    <TextField
                        fullWidth
                        error={touched.title && Boolean(errors.title)}
                        id="title"
                        name="title"
                        onChange={handleChange}
                        value={values.title}
                        helperText={touched.title && errors.title}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{"mb":1}}>Description</Typography>
                    <TextField
                        fullWidth
                        multiline={true}
                        error={touched.description && Boolean(errors.description)}
                        id="description"
                        name="description"
                        onChange={handleChange}
                        value={values.description}
                        helperText={touched.description && errors.description}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{"mb":1}}>Room Type</Typography>
                    <FormControl fullWidth>
                        <Select
                            id="roomType"
                            error={touched.roomType && Boolean(errors.roomType)}
                            value={values.roomType}
                            onChange={handleSelectValue}
                        >
                            {roomTypes.map(roomType => <MenuItem value={roomType.id}>{roomType.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{"mb":1}}>Available From</Typography>
                    <DatePicker
                        inputFormat="DD/MM/yyyy"
                        value={values.availableFrom}
                        onChange={handleDateValue}
                        renderInput={(params) => <TextField error={errors.availableFrom!==undefined} type="date" fullWidth {...params} />}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{"mb":1}}>Video</Typography>
                    <TextField
                        fullWidth
                        id="video"
                        name="video"
                        error={touched.video && Boolean(errors.video)}
                        onChange={handleChange}
                        value={values.video}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{"mb":1}}>View360</Typography>
                    <TextField
                        fullWidth
                        id="view360"
                        name="view360"
                        error={touched.view360 && Boolean(errors.view360)}
                        onChange={handleChange}
                        value={values.view360}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{"mb":1}}>Latitude</Typography>
                    <TextField
                        fullWidth
                        id="latitude"
                        name="latitude"
                        error={touched.latitude && Boolean(errors.latitude)}
                        onChange={handleChange}
                        value={values.latitude}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{"mb":1}}>Longitude</Typography>
                    <TextField
                        fullWidth
                        id="longitude"
                        name="longitude"
                        error={touched.longitude && Boolean(errors.longitude)}
                        onChange={handleChange}
                        value={values.longitude}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography sx={{"mb":1}}>Weekly price</Typography>
                    <TextField
                        fullWidth
                        id="weeklyPrice"
                        type="number"
                        name="weeklyPrice"
                        error={touched.weeklyPrice && Boolean(errors.weeklyPrice)}
                        onChange={handleChange}
                        value={values.weeklyPrice}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button fullWidth variant={"contained"} type="submit">Save</Button>
                </Grid>
            </Grid>


        </form>)
}
