import {Button, Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {FormElement, FormGeneratorContext, FormGeneratorContextProvider} from "form-generator";
import {buyCredits} from "../../../api/creditsApi";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {SetStateAction} from "react";
import buyCreditsFormDescriptor from "./buyCreditsFormType";
import {useLocation} from "react-router";

interface BuyCreditsDialogInterface{
    open:boolean,
    setOpen:React.Dispatch<SetStateAction<boolean>>
}

export default function ({open,setOpen}:BuyCreditsDialogInterface){
    const location = useLocation()
    return <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            You don't have enough credits!
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">

            </DialogContentText>
            <FormGeneratorContextProvider formDescriptor={buyCreditsFormDescriptor} onSubmit={(values)=>{
                buyCredits({...values, credits:parseInt(values.credits)},location.pathname)
            }}>
                <FormGeneratorContext.Consumer>
                    {()=>{
                        return <Grid container>
                            <Grid xs={12} item>
                                <FormElement accessor={"credits"}/>
                            </Grid>
                            <Grid xs={12} item style={{zIndex:300}}>
                                <Button onClick={()=>setOpen(false)}>Not now</Button>
                                <Button type={"submit"} autoFocus>
                                    Ok!
                                </Button>
                            </Grid>
                        </Grid>
                    }}
                </FormGeneratorContext.Consumer>
            </FormGeneratorContextProvider>
        </DialogContent>
    </Dialog>
}
