import * as Yup from "yup";
import React from "react";
import {useNavigate} from "react-router";
import {editPropertyRoute} from "../../../router/frontend-routes";
import {Button, Card, CardContent, CardHeader, Divider, Grid} from "@mui/material";
import {toast} from "react-toastify";
import GoogleInput from "../../../Google/GoogleInput";
import {saveProperty} from "../../../api/property/propertyApi";
import {
    FormDescriptor,
    FormElement,
    FormElements,
    FormGeneratorContext,
    FormGeneratorContextProvider
} from "form-generator";


const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required').nullable(),
    address: Yup.string().required('Address is required').nullable(),
    latitude: Yup.number().required('Latitude is required').nullable(),
    longitude: Yup.number().required('Longitude is required').nullable(),
    postcode: Yup.string().required('Postcode is required').nullable(),
});

const elements:FormElements = [
    {accessor:"name", Header:"Property name", type:"text"},
    {accessor:"address", Header:"Address", type:"text"},
    {accessor:"latitude", Header:"Latitude", type:"float"},
    {accessor:"longitude", Header:"Longitude", type:"float"},
    {accessor:"postcode", Header:"Postcode", type:"text"},
    {accessor:"addressAdminArea1", Header:"Admin area 1", type:"text"},
    {accessor:"addressAdminArea2", Header:"Admin area 2", type:"text"},
    {accessor:"addressCountry", Header:"Country", type:"text"},
    {accessor:"addressPostalTown", Header:"Postal Town", type:"text"},
    {accessor:"addressStreetNumber", Header:"Street number", type:"text"},
    {accessor:"title", Header:"Title", type:"text"},
    {accessor:"description", Header:"Street number", type:"wysiwyg"}
]

const initialValues = {name:null, address:null, latitude:null, longitude:null, postcode:null, title:"", description:""}

const formDescriptor = new FormDescriptor({elements,initialValues,validationSchema})

export default function NewProperty() {

    const navigate = useNavigate()

    return <Card>
        <CardHeader title={"Create a new property"}/>
        <CardContent>
            <FormGeneratorContextProvider
                formDescriptor={formDescriptor}
                onSubmit={(values) => {
                    values.latitude = parseFloat(values.latitude)
                    values.longitude = parseFloat(values.longitude)
                    saveProperty(values).then((response) => navigate(editPropertyRoute(response.data.id))).catch((response) => toast.error("An error happened"))
                }}>
                <FormGeneratorContext.Consumer>
                    {({setFieldValue,values}) => {
                        const setLatitude = (value: any) => {
                            setFieldValue("latitude", value)
                        }
                        const setLongitude = (value: any) => {
                            setFieldValue("longitude", value)
                        }
                        const setAddress = (value: any) => {
                            setFieldValue("address", value)
                        }
                        const setPostcode = (value: any) => {
                            setFieldValue("postcode", value)
                        }

                        const setAddressStreetNumber = (value: any) => {
                            setFieldValue("addressStreetNumber", value)
                        }

                        const setAddressCountry = (value: any) => {
                            setFieldValue("addressCountry", value)
                        }

                        const setAddressPostalTown = (value: any) => {
                            setFieldValue("addressPostalTown", value)
                        }

                        const setAddressAdminArea1 = (value: any) => {
                            setFieldValue("addressAdminArea1", value)
                        }

                        const setAddressAdminArea2 = (value: any) => {
                            setFieldValue("addressAdminArea2", value)
                        }

                        return <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormElement accessor={"name"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                                <h5 className="mt-3 mb-0">Search the the property address in the following box</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <GoogleInput setPostcode={setPostcode} setAddress={setAddress} setLatitude={setLatitude} setLongitude={setLongitude} setAddressStreetNumber={setAddressStreetNumber} setAddressAdminArea1={setAddressAdminArea1} setAddressAdminArea2={setAddressAdminArea2} setAddressCountry={setAddressCountry} setAddressPostalTown={setAddressPostalTown}/>
                                <Divider className="my-3"/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormElement accessor={"address"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"latitude"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"longitude"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <FormElement accessor={"postcode"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"addressStreetNumber"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"addressPostalTown"}/>
                            </Grid>

                            <Grid item xs={6}>
                                <FormElement accessor={"addressCountry"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"addressAdminArea1"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"addressAdminArea2"}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant={"contained"} type="submit">Save</Button>
                            </Grid>

                        </Grid>
                    }}
                </FormGeneratorContext.Consumer>
            </FormGeneratorContextProvider>
        </CardContent>
    </Card>
}
