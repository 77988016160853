import React from 'react'
import {
    FormDescriptor,
    FormElement,
    FormElements,
    FormGeneratorContext,
    FormGeneratorContextProvider
} from "form-generator";
import authClient from "../../../api/authClient";
import {createCompanyUser} from "../../../router/api-routes";
import useCurrentUser from "../../../authentication/useCurrentUser";
import {Button, Card, CardContent,CardHeader, Grid} from "@mui/material";

const elements:FormElements = [
    {accessor:"name", Header:"Name", type:"text"},
    {accessor:"surname", Header:"Surname", type:"text"},
    {accessor:"email", Header:"Email", type:"text"},
    {accessor:"password", Header:"Password", type:"password"},
]
const initialValues = {
    name:"", surname: "", email:""
}

const formDescriptor = new FormDescriptor({elements, initialValues})

export default function NewUser(){
    const currentUser = useCurrentUser()
    const onSubmit = (values:any) => authClient.post(createCompanyUser(currentUser.company),values)

    return <FormGeneratorContextProvider formDescriptor={formDescriptor} onSubmit={onSubmit}>
        <FormGeneratorContext.Consumer>
            {()=>{
                return <Card>
                    <CardHeader title={"Create a new user"}/>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormElement accessor={"name"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"surname"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"email"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormElement accessor={"password"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained">Save</Button>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            }}
        </FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>
}
