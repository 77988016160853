import React, {useEffect, useMemo, useState} from 'react'
import AdsCard from "./AdsCard";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import authClient, {addQueryParams} from "../../../api/authClient";
import {
    createSubscription,
    getRooms,
    switchBoostStatus, switchPropertyActiveRoute,
    switchPropertyAdsStatusRoute, switchPropertyAdvertisabilityRoute,
    switchRoomStatus
} from "../../../router/api-routes";
import {useLocation, useNavigate} from "react-router";
import {editPropertyRoute, newAd, newProperty} from "../../../router/frontend-routes";
import Button from "@mui/material/Button";
import _ from 'lodash';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import {RoomInterface} from "../../../models/Room";
import {toast} from "react-toastify";
import {NavLink} from "react-router-dom";
import BuyCreditsDialog from "../BuyCredits/BuyCreditsDialog";
import {FormElement, FormGeneratorContext, FormGeneratorContextProvider} from "form-generator";
import listAdsFormDescriptor from "./ListAdsFormType";

export default function AdsList(){

    const [ads, setAds] = useState<RoomInterface[]>([]);
    const [queryParamsFilter, setQueryParamsFilter] = useState({})
    const [groupedAds, setGroupedAds] = useState<_.Dictionary<RoomInterface[]>|undefined>();
    const navigate = useNavigate();
    const [openSubscription, setOpenSubscription] =useState(false)
    const [openPAYGO, setOpenPAYGO] =useState(false)
    const location = useLocation();

    const getAds = (queryParams:any) =>authClient.get(addQueryParams(getRooms,queryParams)).then(response => {
        setAds(response.data["hydra:member"])
    })


    const switchAdStatus = (id:number) => authClient.post(switchRoomStatus(id)).then((response)=>{
        getAds(queryParamsFilter)
    }).catch(error =>{
        const content = error.response.data.content
        if(content.errorCode === "werooms.missingRoomData"){
            toast.error(content.message)
        }else if(content.errorCode === "werooms.missingCredits"){
            setOpenPAYGO(true)
        }else{
            console.log("SUBSCRIPTION NOT ACTIVE!")
            setOpenSubscription(true)
            //toast.error(content.message)
        }

    } )

    // @ts-ignore
    const filters = <FormGeneratorContextProvider formDescriptor={listAdsFormDescriptor} onSubmit={(e)=>{
        setQueryParamsFilter(e)
    }}>
        <FormGeneratorContext.Consumer>
            {()=>{
                return <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <FormElement placeholder={"Search by title"} label={false} accessor={"title"}/>
                    </Grid>
                    <Grid item xs={2}>
                        <Button style={{height:"100%"}} fullWidth variant="contained" type="submit">Search</Button>
                    </Grid>
                </Grid>
            }}
        </FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>

    const switchBoostStatusHandler = (id:number) => authClient.post(switchBoostStatus(id)).then((response)=>{
        getAds(queryParamsFilter)
    }).catch(error =>{
        const content = error.response.data.content
        if(content.errorCode === "werooms.missingRoomData"){
            toast.error(content.message)
        }else if(content.errorCode === "werooms.missingCredits"){
            setOpenPAYGO(true)
        }else{
            toast.error(content.message)
        }
    } )

    const activeRooms = useMemo(()=>{
        return ads.filter(ad => ad.active)
    },[ads])

    const boostedRooms = useMemo(()=>{
        return ads.filter(ad => ad.boosted)
    },[ads])


    const switchPropertyAdsStatus = (id:number) => authClient.post(switchPropertyAdsStatusRoute(id)).then((response)=>{
        getAds(queryParamsFilter)
    }).catch(error =>{
        const message = error.response.data.message
        setOpenSubscription(true)
    } )

    const switchPropertyAdvertisability = (id:number) => authClient.post(switchPropertyAdvertisabilityRoute(id)).then((response)=>{
        getAds(queryParamsFilter)
    })

    const switchPropertyActive = (id:number) => authClient.post(switchPropertyActiveRoute(id)).then((response)=>{
        getAds(queryParamsFilter)
    })

    const createSubscriptionHandler = () => authClient.post(createSubscription(location.pathname)).then(response => window.location.href = response.data.url).catch(error => toast.error(error.response.data.message))

    useEffect(()=>{
        getAds(queryParamsFilter)
    },[queryParamsFilter])

    useEffect(()=>{
        if(ads.length>0){
            const groupedRooms = _.groupBy(ads,((room:RoomInterface) =>{
                return (room.property) ? room.property.id : undefined;
            }));
            setGroupedAds(groupedRooms);
        }else{
            // @ts-ignore
            setGroupedAds([])
        }
    },[ads])

    const adsToShow =  groupedAds ? Object.entries(groupedAds).map(([key,rooms])=> {

        if(rooms[0] && rooms[0].property){
            const property = rooms[0].property
            return <Card className="mb-3" sx={{maxWidth: 700}}>
                <CardHeader title={<NavLink to={editPropertyRoute(key)}>{rooms[0].property.name}</NavLink>} subheader={rooms[0].property.address}/>
                <CardContent>
                    {rooms.map((room:RoomInterface, index:number)=><AdsCard roomAdvertisable={!property.advertisable} key={index} room={room} switchStatusHandler={switchAdStatus} switchBoostStatusHandler={switchBoostStatusHandler}/>)}
                </CardContent>
                <CardActions>
                    <Button variant="outlined" onClick={()=>switchPropertyAdsStatus(parseInt(key))} disabled={!(rooms.some(room => !room.active))}>Enable all rooms</Button>
                    <Button variant="outlined" onClick={()=>switchPropertyAdvertisability(parseInt(key))}>{property.advertisable? "Advertise rooms" : "Advertise property"}</Button>
                    <Button variant="outlined" onClick={()=>switchPropertyActive(parseInt(key))}>{property.active ? "Active": "Inactive"}</Button>
                </CardActions>
            </Card>
        }
        else{
            return rooms.map((room:RoomInterface)=><AdsCard roomAdvertisable={room.property === undefined ? true: (!room.property.advertisable)} switchBoostStatusHandler={switchBoostStatusHandler} switchStatusHandler={switchAdStatus} room={room}/>)
        }
    }) : [];

    return <>
        <Grid id="filtersContainer" container className={"mb-3"} spacing={2}>
            <Grid item xs={8}>
                <Card sx={{maxWidth: 700}}>
                    <CardHeader title={"Room filters"}/>
                    <CardContent>{filters}</CardContent>
                </Card>
            </Grid>
            <Grid item xs={4} className="d-flex align-items-end">
                <div>
                    <Button variant="contained" className={"me-2"} onClick={()=>navigate(newAd)}>New Room</Button>
                    <Button variant="contained" onClick={()=>navigate(newProperty)}>New Property</Button>
                </div>

            </Grid>
        </Grid>
        <Grid id="listingContainer" container spacing={2}>
            <Grid item xs={8}>
                <Grid container>
                    {adsToShow.map((item,index)=><Grid key={index} item xs={12}>{item}</Grid>)}
                    <Dialog
                        open={openSubscription}
                        onClose={()=>setOpenSubscription(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Your subscription is not active.
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Do you want to subscribe?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" onClick={()=>setOpenSubscription(false)}>Not now</Button>
                            <Button variant="contained" onClick={()=>createSubscriptionHandler()} autoFocus>
                                Ok!
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <BuyCreditsDialog open={openPAYGO} setOpen={setOpenPAYGO}/>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Card sx={{maxWidth: 700}}>
                    <CardHeader title={"Statistics"}/>
                    <CardContent>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Active: {activeRooms.length}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {activeRooms.map((activeRoom => <ListItem key={activeRoom.uuid}>
                                            <ListItemText primary={<a href={`#${activeRoom.uuid}`}>{activeRoom.title}</a>}/>
                                        </ListItem>)
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Boosted: {boostedRooms.length}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List>
                                    {boostedRooms.map((boostedRoom => <ListItem key={boostedRoom.uuid}>
                                            <ListItemText primary={<a href={`#${boostedRoom.uuid}`}>{boostedRoom.title}</a>}/>
                                        </ListItem>)
                                    )}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                    <CardActions>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </>
}
