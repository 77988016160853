import {useEffect} from "react";
import {useGetCompany} from "../../../api/admin/companiesApi";
import {useParams} from "react-router";
import React from 'react'

export default function ViewCompany(){

    const params = useParams()
    const {company,getCompany} = useGetCompany()

    useEffect(()=>{
        // @ts-ignore
        getCompany(params.id)
    },[params.id])

    return <div>
        {company?.companyName}
    </div>
}
