import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import React, {useState} from 'react';

import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import {Button, Grid } from "@mui/material";


const options = {
    //componentRestrictions: { country: "uk" },
    fields: ["geometry"],
    strictBounds: false,
    types: [],
};

interface GoogleInputInterface{
    setLatitude: (value:any) => void
    setLongitude: (value:any) => void
    setAddress: (value:any) => void
    setPostcode:(value:any)=>void
    setAddressStreetNumber:(value:any)=>void
    setAddressCountry:(value:any)=>void
    setAddressPostalTown:(value:any)=>void
    setAddressAdminArea1:(value:any)=>void
    setAddressAdminArea2:(value:any)=>void

}

export default function GoogleInput({setLatitude,setPostcode, setLongitude,setAddress,setAddressAdminArea1,setAddressAdminArea2,setAddressCountry,setAddressPostalTown,setAddressStreetNumber}:GoogleInputInterface){
    const [value,setValue] = useState();
    const onChange = (result:any) => {
        geocodeByPlaceId(result.value.place_id)
            .then(results => {
                const firstResult = results[0]
                const location = firstResult["geometry"]["location"]
                const addressComponent = firstResult["address_components"].find(addressComponent => addressComponent.types.includes("postal_code"))
                setPostcode(addressComponent?.long_name ?? null);
                const streetNumber= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("street_number"))
                setAddressStreetNumber(streetNumber?.long_name ?? null);
                const adminArea1= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("administrative_area_level_1"))
                setAddressAdminArea1(adminArea1?.long_name ?? null);
                const adminArea2= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("administrative_area_level_2"))
                setAddressAdminArea2(adminArea2?.long_name?? null);
                const country= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("country"))
                setAddressCountry(country?.long_name ?? null);
                const postalTown= firstResult["address_components"].find(addressComponent => addressComponent.types.includes("locality"))
                setAddressPostalTown(postalTown?.long_name ?? null);
                setAddress(result.label)
                setLongitude(location.lng().toString())
                setLatitude(location.lat().toString())
            })
            .catch(error => console.error(error));
    }

    return <Grid container spacing={2}>
        <Grid item xs={11}>
            <GooglePlacesAutocomplete autocompletionRequest={options} apiKey={"AIzaSyChBxMuwMhMtZvgZSzWfGN70dK2v0HP5K0"}
                                     selectProps={{
                                         value:value,
                                         onInputChange:setValue,
                                         onChange: onChange,
                                     }}/>
        </Grid>
        <Grid item xs={1}>
            <Button fullWidth variant={"contained"} onClick={()=>{setValue(undefined)}}>Reset</Button>
        </Grid>
    </Grid>
}
