type SubscriptionType = "subscription" | "pay_as_you_go"

export interface CompanyInterface{
    companyName:string
    boosts: number
    credits:number
    id:number
    paymentProcedureActive:boolean
    portalSubscriptions:any[]
    subscriptionActive:boolean
    subscriptionType:SubscriptionType
    superCompany:boolean
    weroomsPlan:boolean
    hasSmoobu:boolean
}

export class Company{
    companyName:string
    boosts: number
    credits:number
    id:number
    paymentProcedureActive:boolean
    portalSubscriptions:any[]
    subscriptionActive:boolean
    subscriptionType:SubscriptionType
    superCompany:boolean
    weroomsPlan:boolean
    hasSmoobu:boolean


    constructor({companyName,boosts,credits,id,paymentProcedureActive,portalSubscriptions,subscriptionActive,subscriptionType,superCompany,weroomsPlan, hasSmoobu}:CompanyInterface) {
        this.companyName = companyName;
        this.boosts = boosts
        this.credits = credits
        this.id = id
        this.paymentProcedureActive = paymentProcedureActive
        this.portalSubscriptions = portalSubscriptions
        this.superCompany = superCompany
        this.subscriptionActive = subscriptionActive
        this.subscriptionType = subscriptionType
        this.weroomsPlan = weroomsPlan
        this.hasSmoobu = hasSmoobu
    }

    getSubscriptionType(){
        if(this.subscriptionType === "subscription"){
            return "subscription";
        }
        if(this.subscriptionType === "pay_as_you_go"){
            return "Pay as you go";
        }
        return "No plan"
    }
}
