import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {editAd} from "../../../router/frontend-routes";
import {useNavigate} from "react-router";
import CardImageCarousel from './CardImageCarousel';
import {RoomInterface} from "../../../models/Room";
import {Button, CardActions, FormControlLabel, FormGroup, Grid, Switch} from "@mui/material";
import {NavLink} from "react-router-dom";
import BoltIcon from '@mui/icons-material/Bolt';
import moment from "moment";

interface AdsCardInterface {
    room: RoomInterface,
    roomAdvertisable:boolean
    switchStatusHandler?: (id: number) => Promise<string | number | void>
    switchBoostStatusHandler?: (id: number) => Promise<string | number | void>
}

export default function AdsCard({room, switchStatusHandler, roomAdvertisable, switchBoostStatusHandler}: AdsCardInterface) {
    const {id, description, title, allImages, active, boosted,activeEndDate} = room;
    const navigate = useNavigate();

    const switcher = switchStatusHandler && <FormGroup>
        <FormControlLabel control={<Switch checked={active} disabled={!roomAdvertisable} onChange={() => switchStatusHandler(id)}></Switch>}
                          label={active ? `Active until ${moment(activeEndDate).format('MMMM Do YYYY')}` : "In active"}/>
    </FormGroup>

    return (
        <Card sx={{maxWidth: 700, marginBottom: 3}}>
            <CardHeader
                title={<div className="d-flex justify-content-between align-items-center">{switcher}<NavLink to={editAd(id)}>{title}</NavLink></div>}
                subheader={<p id={room.uuid}></p>}
            />
            <CardContent>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        {allImages.length > 0 ? <div style={{height: 250}}><CardImageCarousel images={allImages}/></div> : <div style={{height: 250, borderRadius: 10, width: "100%", background: "lightgray"}}
                                      className={"d-flex justify-content-center align-items-center"}>No picture
                            available</div>}
                    </Grid>
                    <Grid item xs={12} md={6} className="px-3">
                        <Typography variant="body2" color="text.secondary">
                            <div dangerouslySetInnerHTML={{"__html": description}}/>
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                {switchBoostStatusHandler && !boosted && <Button onClick={() => {switchBoostStatusHandler(id)}} disabled={boosted} variant="contained" color="secondary" startIcon={<BoltIcon/>}>Boost</Button>}
                {switchBoostStatusHandler && boosted && <Typography color="secondary" >{`Boost expires on ${moment(room.boostedEndDate).format('MMMM Do YYYY')}`}</Typography>}
            </CardActions>
        </Card>
    );
}
