import React, {useEffect} from "react";
import {Button} from "@mui/material";
import authClient from "../../../../../api/authClient";
import {createCompanySubscriptions} from "../../../../../router/api-routes";
import useCurrentUser from "../../../../../authentication/useCurrentUser";

interface PortalSubscriptionConfigurationInterface{
    generateSubscriptions: () => Promise<void>
}

export default function PortalSubscriptionsConfiguration({generateSubscriptions}:PortalSubscriptionConfigurationInterface){
    return <>
        <p>No configuration for Portal Subscriptions</p>
        <Button onClick={()=>generateSubscriptions()} variant="outlined">Generate push portal subscriptions</Button>
    </>
}
