import authClient from "../authClient";
import {AUTH_ENTRYPOINT} from "../../router/api-routes";

const changeRoomVisibilityRoute = (roomPortalId:string) => AUTH_ENTRYPOINT + `/api/room_portal_infos/change_room_visibility/${roomPortalId}`
const pushRoute = (roomPortalId:string) => AUTH_ENTRYPOINT + `/api/room_portal_infos/push/${roomPortalId}`

export const changeRoomVisibility = (roomPortalId:string) => {
    return authClient.post(changeRoomVisibilityRoute(roomPortalId))
}

export const pushRoom = (roomPortalId:number) => {
    return authClient.post(pushRoute(roomPortalId.toString()))
}
