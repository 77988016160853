import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ImageList,
    ImageListItem,
    TextField
} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import authClient from "../../../../api/authClient";
import {editRoom, savePictures} from "../../../../router/api-routes";
import {useParams} from "react-router";
import {RoomInterface, Image, Room} from "../../../../models/Room";

export interface FileToUpload{
    base64:string|ArrayBuffer|null;
    filename:string;
    title:string;
}

const readFileAsync = async (file:File) => {
    const content = await readFile(file)
    return content

}

const readFile = (file:File) => new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = evt => resolve({filename:file.name,base64:reader.result, title:file.name})
    reader.readAsDataURL(file)
})

const readFiles = async (files:FileList) => {
    const fileListLength = files.length;
    const filesRead = []
    for (let i = 0; i < fileListLength; i++) {
        const file = await readFileAsync(files[i])
        filesRead.push(file)
    }
    return filesRead;
}



interface AdPictureUploadInterface{
    room?:Room,
    getRoom: ()=>void
}

export default function AdPictureUploadTab({room, getRoom}:AdPictureUploadInterface){

    const params = useParams()
    const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);

    // @ts-ignore
    const onSubmit = () => {
        // @ts-ignore
        const finalImages = [...room.allImages, ...filesToUpload]
        authClient.patch(savePictures(params.id), {images:finalImages} ).then(response=> {
            getRoom()
        })
    }

    const fileHandleChange= (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const files = e.target.files;
        // @ts-ignore
        readFiles(files).then(result => setFilesToUpload(result))

    };

    const [open,setOpen]= useState(false)
    const [currentImage, setCurrentImage] = useState<Image|undefined>()

    return room ? <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/*<Typography sx={{"mb":1}}>Room images</Typography>*/}
                    {room.allImages.length> 0 && <ImageList sx={{height: 450}} cols={3} rowHeight={164}>
                        {room.allImages.map(image => <ImageListItem key={image.id}>
                            <img
                                src={`${image.url}?w=164&h=164&fit=crop&auto=format`}
                                alt={image.filename}
                                loading="lazy"
                                onClick={() => {
                                    setCurrentImage(image)
                                    setOpen(true)
                                }}
                            />
                        </ImageListItem>)
                        }
                    </ImageList>}
                    <Dialog
                        open={open}
                        onClose={()=>setOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth="xl"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {currentImage && currentImage.filename}
                        </DialogTitle>
                        <DialogContent>
                            {currentImage && <img
                                width="100%"
                                src={`${currentImage.url}?w=164&h=164&fit=crop&auto=format`}
                                alt={currentImage.filename}
                                loading="lazy"
                            />}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{}}>Remove</Button>
                        </DialogActions>
                    </Dialog>
                    <TextField
                        fullWidth
                        type="file"
                        id="files"
                        inputProps={{ multiple: true }}
                        name="files"
                        onChange={fileHandleChange}

                    />
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={onSubmit}>Submit</Button>
                </Grid>
            </Grid>
    </div> : <div></div>
}
