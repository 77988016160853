import {FormDescriptor, FormElements} from "form-generator";

const buyCreditsFormElements:FormElements = [
    {
        accessor: "credits",
        Header: "Credits",
        type:"select",
        options: [
            {label:"1", value:"1"},
            {label:"5", value:"5"},
            {label:"10", value:"10"},
            {label:"20", value:"20"}
        ]
    }
]

const initialValues = {
    credits:10
}
const buyCreditsFormDescriptor = new FormDescriptor({elements:buyCreditsFormElements,initialValues})
export default buyCreditsFormDescriptor
