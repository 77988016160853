import {Button, Grid} from "@mui/material";
import React from "react";
import {toast} from "react-toastify";
import * as Yup from "yup";
import {PropertyDTO} from "../../../../api/property/dto/PropertyDTO";
import {
    FormDescriptor,
    FormElement,
    FormElements,
    FormGeneratorContext,
    FormGeneratorContextProvider
} from "form-generator";


const validationSchema = Yup.object().shape({
});

const formElements:FormElements = [
    {accessor:"title", Header:"Marketing title", type:"text"},
    {accessor:"description", Header:"Description", type:"wysiwyg"},
]
const initialValues = {title:null, description:null}

const formDescriptor = new FormDescriptor({elements:formElements, initialValues,validationSchema})

interface PropertyInfoInterface{
    property: PropertyDTO,
    savePropertyHandler: (property:PropertyDTO) => Promise<any>
}

export default function PropertyMarketingTab({property,savePropertyHandler}:PropertyInfoInterface){

    const onSubmit = (values:any) => savePropertyHandler(values).then((response)=>toast("Property saved")).catch((response)=>toast.error("An error happened"))


    return <FormGeneratorContextProvider existingValue={property} onSubmit={onSubmit} formDescriptor={formDescriptor}>
        <FormGeneratorContext.Consumer>
            {()=>{
                return <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormElement accessor={"title"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"description"}/>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" type="submit">Save</Button>
                    </Grid>
                </Grid>
            }}
        </FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>
}
