import {weroomsManagedProperties} from "../../router/frontend-routes";
import * as React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';


const routes = [
    {icon: <HolidayVillageIcon />, text:"Properties", route:weroomsManagedProperties},
]


export default function WeroomsAgentDashboard({content = <></>}:any){
    return <>
        <Dashboard content={content} routes={routes} />
    </>
}
