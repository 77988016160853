import React from 'react'
import CompaniesList from "./CompaniesList";
import {useGetCompanies} from "../../../api/admin/companiesApi";
import {useEffect} from "react";

export default function Companies(){

    const {companies,getCompanies} = useGetCompanies()

    useEffect(()=>{
        getCompanies()
    },[])

    return <CompaniesList companies={companies}/>
}
