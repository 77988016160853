import jwt_decode from "jwt-decode";
import {check_login, refreshToken, registration} from "../router/api-routes";
import client from "../api/client";

export const authProvider = {
    login: ({ email, password }) =>  {
        return client.post(check_login,JSON.stringify({email, password}))
            .then(response =>
            {
                localStorage.setItem('token', JSON.stringify(response.data.token));
                localStorage.setItem('refreshToken', JSON.stringify(response.data["refresh_token"]));
                return jwt_decode(response.data.token)
            })
    },
    register: (values) =>  {
        return client.post(registration,JSON.stringify(values))
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }

            }).catch(error=>{

            })
    },
    refresh:()=>{
        return client.post(refreshToken,JSON.stringify({"refresh_token": localStorage.getItem('refreshToken').slice(1,-1)}))
            .then(response =>
            {
                localStorage.setItem('token', JSON.stringify(response.data.token));
                localStorage.setItem('refreshToken', JSON.stringify(response.data["refresh_token"]));
                return jwt_decode(response.data.token)
            })
    },
    logout: ()=>{
        localStorage.removeItem("token");
        return Promise.resolve().then(()=>window.location.href="/signin");
    } ,
    checkAuth: () => localStorage.getItem('token')
        ? Promise.resolve()
        : Promise.reject(),
    checkError: error => Promise.resolve(),
    getPermissions: ()=>{
        const token = localStorage.getItem("token");
        const decodedToken = jwt_decode(token);
        const {roles} = decodedToken;
        return roles ? Promise.resolve(roles) : Promise.reject();

    },
    getIdentity: () =>{
        const token = localStorage.getItem("token");
        const decodedToken = jwt_decode(token);
        console.log("decoded token", decodedToken)
        const {username: fullName, roles, id} = decodedToken;
        return  {fullName, roles, id};

    },
    getRoles: () =>{
        const token = localStorage.getItem("token");
        if(token){
            console.log("token",token)
            const decodedToken = jwt_decode(token);
            const {username: fullName, roles, id} = decodedToken;
            return  roles;
        }

        return []

    },
    isAdmin: () =>{
        const token = localStorage.getItem("token");
        if(token){
            const decodedToken = jwt_decode(token);
            const {admin} = decodedToken;
            return  admin;
        }

        return false

    },
    checkTokenValidity: () => {
        const token = localStorage.getItem("token");
        if(token){
            const decodedToken = jwt_decode(token);
            const {exp} = decodedToken;
            const timeNow = Date.now();

            return timeNow/1000 < exp;
        }else{
            return false;
        }
    }
};
