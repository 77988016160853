import {Image, Room} from "../../../../models/Room";
import {useParams} from "react-router";
import React, {ChangeEvent, useState} from "react";
import authClient from "../../../../api/authClient";
import {getProperty, savePictures, savePropertyPictures} from "../../../../router/api-routes";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ImageList,
    ImageListItem,
    TextField
} from "@mui/material";
import {FileToUpload} from "../../Ads/Tabs/AdPictureUploadTab";
import {PropertyDTO} from "../../../../api/property/dto/PropertyDTO";

const readFileAsync = async (file:File) => {
    const content = await readFile(file)
    return content

}

const readFile = (file:File) => new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = evt => resolve({filename:file.name,base64:reader.result, title:file.name})
    reader.readAsDataURL(file)
})

const readFiles = async (files:FileList) => {
    const fileListLength = files.length;
    const filesRead = []
    for (let i = 0; i < fileListLength; i++) {
        const file = await readFileAsync(files[i])
        filesRead.push(file)
    }
    return filesRead;
}



interface AdPictureUploadInterface{
    property?:PropertyDTO,
    getProperty: ()=>void
}

export default function PropertyPicturesTab({property, getProperty}:AdPictureUploadInterface){

    const params = useParams()
    const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);

    // @ts-ignore
    const onSubmit = () => {
        // @ts-ignore
        const finalImages = [...property.allImages, ...filesToUpload]
        authClient.patch(savePropertyPictures(params.id), {images:finalImages} ).then(response=> {
            getProperty()
        })
    }

    const fileHandleChange= (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        const files = e.target.files;
        // @ts-ignore
        readFiles(files).then(result => setFilesToUpload(result))

    };

    const [open,setOpen]= useState(false)
    const [currentImage, setCurrentImage] = useState<Image|undefined>()

    return property ? <div>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {/*<Typography sx={{"mb":1}}>Room images</Typography>*/}
                {property.allImages.length> 0 && <ImageList sx={{height: 450}} cols={3} rowHeight={164}>
                    {property.allImages.map(image => <ImageListItem key={image.id}>
                        <img
                            src={`${image.url}?w=164&h=164&fit=crop&auto=format`}
                            alt={image.filename}
                            loading="lazy"
                            onClick={() => {
                                setCurrentImage(image)
                                setOpen(true)
                            }}
                        />
                    </ImageListItem>)
                    }
                </ImageList>}
                <Dialog
                    open={open}
                    onClose={()=>setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xl"
                >
                    <DialogTitle id="alert-dialog-title">
                        {currentImage && currentImage.filename}
                    </DialogTitle>
                    <DialogContent>
                        {currentImage && <img
                            width="100%"
                            src={`${currentImage.url}?w=164&h=164&fit=crop&auto=format`}
                            alt={currentImage.filename}
                            loading="lazy"
                        />}
                    </DialogContent>
                    <DialogActions>
                        <Button variant={"outlined"} onClick={()=>setOpen(!open)}>Close</Button>
                        <Button variant={"contained"} onClick={()=>{}}>Remove</Button>
                    </DialogActions>
                </Dialog>
                <TextField
                    fullWidth
                    type="file"
                    id="files"
                    inputProps={{ multiple: true }}
                    name="files"
                    onChange={fileHandleChange}

                />
            </Grid>
            <Grid item xs={6}>
                <Button variant={"contained"} onClick={onSubmit}>Save</Button>
            </Grid>
        </Grid>
    </div> : <div></div>
}
