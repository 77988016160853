import GetCompanyDTO from "./GetCompanyDTO";

interface Credentials{
    key: string,
    type:string,
    value:any
}

interface PortalSubscription{
    portal:string,
    id:number,
    active:boolean,
    credentials:Credentials[]

}

export class PatchCompanyDTO{
    companyName:string
    portalSubscriptions:PortalSubscription[]

    constructor({companyName, portalSubscriptions}:PatchCompanyDTO) {
        this.companyName = companyName;
        this.portalSubscriptions = portalSubscriptions
    }
    static fromGetCompanyDTO(getCompanyDTO:GetCompanyDTO,companyId:number){
        let portalSubscriptions = getCompanyDTO.portalSubscriptions.map(portalSubscription =>{
            return {...portalSubscription, portal:`/api/portals/${portalSubscription.portal.id}`}
        })
        return new PatchCompanyDTO({companyName:getCompanyDTO.companyName, portalSubscriptions});


    }
}
