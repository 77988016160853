import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import List from "@mui/material/List";
import {Divider, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {useNavigate} from "react-router";
import useCurrentUser from "../../authentication/useCurrentUser";

export interface Route{
    icon:any,
    text:string,
    route:any
}

export interface SidebarInterface{
    routes:Route[]
}

export default function Sidebar({routes}:SidebarInterface){
    const currentUser = useCurrentUser()
    const navigate = useNavigate()
    return <React.Fragment>
        <Toolbar sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1],}}>
            <IconButton>
                <ChevronLeftIcon />
            </IconButton>
        </Toolbar>
        <List component="nav">
            <ListItem className="px-5">
                <ListItemText>{currentUser.username}</ListItemText>
            </ListItem>
            <Divider />

            {
                routes.map(({route,icon, text},index) => {
                    return <ListItemButton key={index} onClick={()=>navigate(route)}>
                        <ListItemIcon>
                            {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItemButton>
                })
            }
        </List>
    </React.Fragment>
}
