import {Button, Grid} from "@mui/material";
import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import authClient from "../../../../api/authClient";
import {getPropertyRooms} from "../../../../router/api-routes";
import AdsCard from "../../Ads/AdsCard";
import {RoomInterface} from "../../../../models/Room";
import {PropertyDTO} from "../../../../api/property/dto/PropertyDTO";
import NewRoom from "../../Rooms/NewRoom";

interface RoomsTabInterface{
    property:PropertyDTO;
    updateProperty:()=>void
}

export default function RoomsTab({property,updateProperty}:RoomsTabInterface){

    const [rooms, setRooms] = useState<RoomInterface[]>([]);
    const navigate = useNavigate();

    const [showForm, setShowForm]= useState(false)

    useEffect(()=>{
        authClient.get(getPropertyRooms(property.id)).then(response => setRooms(response.data["hydra:member"]))
    },[])


    return <Grid container spacing={2}>
        <Grid item xs={12}>
            {!showForm && <Button variant="contained" onClick={() => setShowForm(true)}>Add room</Button>}
        </Grid>
        {showForm && <NewRoom property={property} onSave={()=>{setShowForm(!showForm);updateProperty()}}/>}
        {!showForm && rooms.map(room => <Grid item xs={12}>
            <AdsCard roomAdvertisable={room.property === undefined ? true: (!room.property.advertisable)} room={room} />
        </Grid>)}
    </Grid>


}
