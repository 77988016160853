import * as React from "react";
import {useEffect, useState} from "react";
import useCurrentUser from "../../../authentication/useCurrentUser";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Dashboard from "../../../components/Dashboard/Dashboard";
import useGetCompanyInfo from "../../../api/company/useGetCompanyInfo";
import BuyCreditsDialog from "../BuyCredits/BuyCreditsDialog";
import {account, enquiries, myads, properties, users} from "../../../router/frontend-routes";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import MailIcon from '@mui/icons-material/Mail';
import InfoIcon from '@mui/icons-material/Info';
import PeopleIcon from '@mui/icons-material/People';
const basicRoutes = [
    {icon: <HolidayVillageIcon />, text:"My ads", route:myads},
    {icon: <MailIcon />, text:"Enquiries", route:enquiries},
    {icon: <InfoIcon />, text:"Account", route:account},
    {icon: <HolidayVillageIcon />, text:"Properties", route:properties}
]
export default function AgentDashboard({content = <></>}:any){
    const currentUser = useCurrentUser()
    const routes = (currentUser.admin) ? [...basicRoutes,   {icon: <PeopleIcon />, text:"Users", route:users}]: basicRoutes;

    const {getCompanyInfo} = useGetCompanyInfo();
    const [open, setOpen] = useState(false)
    useEffect(()=>{getCompanyInfo()},[])

    const toolbarContent = <ToolbarContent openBuyCredits={()=>{setOpen(true)}}/>
    return <>
        <Dashboard content={content} toolbarContent={toolbarContent} routes={routes} />
        <BuyCreditsDialog open={open} setOpen={setOpen}/>
    </>
}

const ToolbarContent = ({openBuyCredits}:any) => {
    // @ts-ignore
    const {auth} = useSelector(state => state)
    return <>
        <Typography
            component="h6"
            variant="body2"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
        >
        </Typography>
        <div className={"d-flex align-items-center"}>
            <Typography
                component="h6"
                variant="body2"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1, paddingX:3, marginX:3 }}
            >
                Credits {auth && auth.company && auth.company.credits}
            </Typography>
            <Button color={"secondary"} variant={"contained"} onClick={()=>openBuyCredits()}>Buy more</Button>
        </div>
    </>
}
