import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#004862"
        },
        secondary:{
            main: "#E6007E"
        }
    }
});
