import {Room} from "../../../../../models/Room";
import {changeRoomVisibility, pushRoom} from "../../../../../api/room/roomApi";
import {Button, Grid} from "@mui/material";
import React from "react";
import {PortalSubscription, RoomPortalInfo} from "./AdPortalsTab";
import BackendLogoImage from "../../../../reusable/BackendLogoImage";

interface RoomPortalDescriptionInterface{
    roomPortalInfo: RoomPortalInfo
    room:Room
    getRoomPortalInfos: (roomId:number) => void
}

export default function PullRoomPortalDescription({roomPortalInfo, room,getRoomPortalInfos}:RoomPortalDescriptionInterface){
    const portalSubscription = roomPortalInfo.portalSubscription ?? roomPortalInfo.propertyPortalInfo?.portalSubscription
    const portalId = roomPortalInfo.roomPortalId;

    const link = getLink(portalSubscription, portalId)

    const pullRoomHandler= () => pushRoom(roomPortalInfo.id).then(()=>{
        getRoomPortalInfos(room.id)
    })

    return <div className="my-3">
        <Grid container>
            <Grid item xs={6}>
                <BackendLogoImage url={portalSubscription?.portal.logoUrl}/>
            </Grid>
            <Grid item xs={6}>
                {portalId ? <h6>Room is synced</h6> : <h6>Room is not mapped</h6>}
                {link && <div><a href={link} target="_blank">Ad link</a></div>}
                {portalId && <Button color={portalId ? "error" : "primary"} variant="contained" onClick={() => {
                    pullRoomHandler()
                }}>Pull</Button>}
            </Grid>
        </Grid>
    </div>

}

function getLink(portalSubscription:PortalSubscription|undefined, portalId:string){
    if(portalSubscription && portalId){
        if(portalSubscription.portal.name === "lofti"){
            return `https://uat.backoffice.lofti.co/en/units/${portalId}/details`
        }
    }
    return undefined;
}
