export interface PaymentStatusInterface{
    status?: number
}

type PaymentStatusValue = "Accepted" | "Refused" | "Pending"| "Unknown" | "No payment"

export class PaymentStatus{
    status: PaymentStatusValue

    constructor({status}:PaymentStatusInterface) {
        switch(status){
            case 0:{
                this.status = "Refused";
                break;
            }
            case 2:{
                this.status = "Accepted";
                break;
            }
            case 3:{
                this.status = "Pending";
                break;
            }
            case undefined:{
                this.status = "No payment";
                break;
            }
            default:{
                this.status = "Unknown"
                break;
            }
        }
    }
}
