import * as Yup from "yup";
import {FormDescriptor, FormElements} from "form-generator";

const elements:FormElements = [
    {
        accessor:"amenities",
        type:"tags",
        Header:"Amenities"
    },
]
const initialValues = {
    amenities:[],
}
const validationSchema = Yup.object().shape({
});

const roomAmenitiesFormDescriptor = new FormDescriptor({elements,validationSchema,initialValues})
export default roomAmenitiesFormDescriptor
