import authClient from "../authClient";
import {PropertyDTO} from "./dto/PropertyDTO";
import {AUTH_ENTRYPOINT} from "../../router/api-routes";

export const createProperty = AUTH_ENTRYPOINT + "/api/properties"
export const editProperty =  (id:number) =>  AUTH_ENTRYPOINT + `/api/properties/${id}`

export const saveProperty = (values:PropertyDTO) => {
    values.title = values.title ?? values.name
    values.description = values.description ?? "<p></p>"
    return (values.id) ? authClient.patch(editProperty(values.id),values) : authClient.post(createProperty,values)
}
