import {AUTH_ENTRYPOINT} from "../../router/api-routes";
import authClient from "../authClient";
import {useState} from "react";
import GetCompanyDTO from "../../models/company/GetCompanyDTO";


export const getCompaniesRoute = AUTH_ENTRYPOINT + "/api/companies"
export const getCompanyRoute = (id:string) => AUTH_ENTRYPOINT + `/api/companies/${id}`

export const useGetCompanies = () => {
    const [companies, setCompanies] = useState<GetCompanyDTO[]>([])
    const getCompanies = () => authClient.get(getCompaniesRoute).then(result => setCompanies(result.data["hydra:member"]))

    return {companies,getCompanies}
}

export const useGetCompany = () => {
    const [company, setCompany] = useState<GetCompanyDTO|undefined>()
    const getCompany = (id:string) => authClient.get(getCompanyRoute(id)).then(result => setCompany(result.data))

    return {company,getCompany}
}
