import * as Yup from "yup";
import {FormDescriptor, FormElements} from "form-generator";

const elements:FormElements = [
    {
        accessor:"title",
        type:"text",
        Header:"Title"
    }
]
const initialValues = {
    title:null,
}
const validationSchema = Yup.object().shape({
});

const listAdsFormDescriptor = new FormDescriptor({elements,validationSchema,initialValues})
export default listAdsFormDescriptor
