import * as Yup from "yup";
import {FormDescriptor, FormElements} from "form-generator";

const elements:FormElements = [
    {
        accessor:"title",
        type:"text",
        Header:"Title"
    },
    {
        accessor:"description",
        type:"wysiwyg",
        Header:"Description"
    },
    {
        accessor:"roomType",
        type:"select",
        Header:"Room Type",
        options:[]
    },
    {
        accessor:"availableFrom",
        type:"date",
        Header:"Available from",
    },
    {
        accessor:"video",
        type:"text",
        Header:"video"
    },
    {
        accessor:"view360",
        type:"text",
        Header:"View 360"
    },
    {
        accessor:"latitude",
        type:"float",
        Header:"Latitude"
    },
    {
        accessor:"longitude",
        type:"float",
        Header:"longitude"
    },
    {
        accessor:"weeklyPrice",
        type:"price",
        Header:"Weekly Price"
    },
]
const initialValues = {
    title:null,
    description:null,
}
const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').nullable(),
    description: Yup.string().required('Description is required'),
    roomType: Yup.string().required('Room type is required'),
    weeklyPrice: Yup.number().required('Weekly price is required').moreThan(0),
});

const editRoomFormDescriptor = new FormDescriptor({elements,validationSchema,initialValues})
export default editRoomFormDescriptor
