export interface RoomDTOInterface{
    title: string;
    roomType: string;
    description:string;
    video:string;
    view360:string,
    availableFrom?:string;
    price:number
    latitude?:string,
    longitude?:string,
    property?: number,
    boosted?:boolean,
    boostedStartDate?:string,
    boostedEndDate?:string
}

export class RoomDTO{
    title: string;
    roomType: string;
    description:string;
    video:string;
    view360:string;
    availableFrom?:string;
    price:number
    latitude?:number;
    longitude?:number;
    property?: string;
    boosted?:boolean;
    boostedStartDate?:string
    boostedEndDate?:string

    constructor({title,roomType,description,video, view360, availableFrom,price,latitude,longitude,property,boosted,boostedEndDate,boostedStartDate}:RoomDTOInterface) {
        this.title = title;
        this.roomType = `/api/room_types/${roomType}`;
        this.description = description;
        this.video = video;
        this.view360 = view360;
        this.availableFrom = availableFrom;
        this.price = price;
        this.latitude = latitude ? parseFloat(latitude): undefined;
        this.longitude = longitude ? parseFloat(longitude): undefined;
        this.property = property ? `/api/properties/${property}` : undefined;
        this.boosted = boosted;
        this.boostedStartDate = boostedStartDate;
        this.boostedEndDate = boostedEndDate
    }
}

export const defaultRoomDTO = {
    title: '',
    roomType: "",
    description: "",
    video: "",
    view360:"",
    availableFrom:"",
    price:0,
    latitude: "0",
    longitude: "0"
}
