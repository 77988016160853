import {useParams} from "react-router";
import {useEffect, useState} from "react";
import authClient from "../../../api/authClient";
import {acceptPaymentRoute, denyPaymentRoute, getEnquiries, getEnquiryRoute} from "../../../router/api-routes";
import React from 'react'
import Container from "@mui/material/Container";
import {Card, CardContent, CardHeader, Grid,Button} from "@mui/material";
import moment from "moment";
import {editAd} from "../../../router/frontend-routes";
import {NavLink} from "react-router-dom";
import {Enquiry} from "../../../models/Enquiry";

export default function (){
    const params = useParams()
    const [enquiry, setEnquiry] = useState<Enquiry|undefined>()

    const getEnquiry = () => authClient.get(getEnquiryRoute(params.id)).then(response => setEnquiry(new Enquiry(response.data)))
    const paymentReferenceId = enquiry?.paymentReferenceId


    const acceptBooking = (paymentReferenceId:string) => {
        authClient.post(acceptPaymentRoute(paymentReferenceId)).then(response => getEnquiry())
    }

    const denyBooking = (paymentReferenceId:string) =>{
        authClient.post(denyPaymentRoute(paymentReferenceId)).then(response => getEnquiry())
    }

    useEffect(()=>{
        getEnquiry()
    },[params.id])

    return <Container>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card className={"mb-3"}>
                    <CardHeader
                        title={`Enquiry by ${enquiry?.name} ${enquiry?.surname}`}
                        subheader={enquiry?.createdAt && `Received: ${moment(enquiry.createdAt).format("MMMM Do YYYY")}`}
                    />
                </Card>
                <Card className={"mb-3"}>
                    <CardHeader title={`Prospective Tenant`}/>
                    <CardContent>
                        <p>Name: {enquiry?.name}</p>
                        <p>Surname: {enquiry?.surname}</p>
                        <p>Email: {enquiry?.email}</p>
                        <p>Phone number: {enquiry?.phoneNumber}</p>
                    </CardContent>
                </Card>
                <Card className={"mb-3"}>
                    <CardHeader title={`Room and period`}/>
                    <CardContent>
                        Room name: <NavLink to={editAd(enquiry?.room.id)}>{enquiry?.room.title}</NavLink>
                        <p>Start date: {enquiry?.startDate && moment(enquiry.startDate).format("MMMM Do YYYY")}</p>
                        <p>End date: {enquiry?.endDate && moment(enquiry.endDate).format("MMMM Do YYYY")}</p>
                    </CardContent>
                </Card>
                {paymentReferenceId!==undefined && <Card className={"mb-3"}>
                    <CardHeader title={`Booking`}/>
                    <CardContent>
                        {
                            enquiry?.isPending() &&<>
                                <Button onClick={()=>{acceptBooking(paymentReferenceId)}} color={"primary"} variant={"contained"} >Accept</Button>
                                <Button onClick={()=>{denyBooking(paymentReferenceId)}} color={"secondary"} variant={"contained"} >Deny</Button>
                            </>
                        }
                        {
                            enquiry?.isAccepted() &&<>
                                Payment accepted
                            </>
                        }
                        {
                            enquiry?.isRefused() &&<>
                                Payment denied
                            </>
                        }

                    </CardContent>
                </Card>}
            </Grid>
        </Grid>
    </Container>
}
