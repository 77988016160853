export const myads = "/my-ads"
export const newAd = "/ads/new"
export const myAd = (id) => `/my-ads/${id}`
export const editAd = (id) => `/api/rooms/${id}`

export const enquiries = "/enquiries"
export const properties = "/properties"
export const account = "/account"
export const users = "/users"
export const newUser = "/new_user"

export const newProperty = "/property/new"
export const editPropertyRoute = (id) => `/properties/${id}/edit`
export const viewEnquiryRoute = (id) => `/enquiries/${id}`
export const viewUserRoute = (id) => `/users/${id}`
export const viewCompanyRoute = (id) => `/companies/${id}`


export const admin = "/admin"
export const adminCompanies = "/admin/companies"

////
export const weroomsManagedProperties = "/werooms/properties"
