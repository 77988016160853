import {Button, Card, CardContent, CardHeader, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect} from "react";
import useCurrentUser from "../../../../authentication/useCurrentUser";
import authClient from "../../../../api/authClient";
import {editUser, getUser} from "../../../../router/api-routes";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    name: Yup.string(),
    surname: Yup.string(),
    email: Yup.string(),
    phoneNumber: Yup.string()
});

export default function (){
    const currentUser = useCurrentUser();

    useEffect(()=>{
        // @ts-ignore
        authClient.get(getUser(currentUser.id)).then(response => setValues(response.data))
    },[])

    const {values,setValues, touched, errors, handleChange,handleSubmit} = useFormik({
        initialValues: {
            name:"",
            surname:"",
            phoneNumber:"",
            email:"",
        },
        validationSchema,
        onSubmit: (values) => {
            authClient.patch(editUser(currentUser.id), values )
                .then(()=>toast.success('User info saved', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
                .catch(()=>toast.error('An error occurred', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
        }
    });
    return <>
        <CardHeader title={`${values.name} ${values.surname}`}/>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p className="h2">User data</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{"mb":1}}>Name</Typography>
                        <TextField
                            fullWidth
                            error={touched.name && Boolean(errors.name)}
                            id="name"
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                            helperText={touched.name && errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{"mb":1}}>Surname</Typography>
                        <TextField
                            fullWidth
                            error={touched.surname && Boolean(errors.surname)}
                            id="surname"
                            name="surname"
                            onChange={handleChange}
                            value={values.surname}
                            helperText={touched.surname && errors.surname}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{"mb":1}}>Phone Number</Typography>
                        <TextField
                            fullWidth
                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                            id="phoneNumber"
                            name="phoneNumber"
                            onChange={handleChange}
                            value={values.phoneNumber}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography sx={{"mb":1}}>Email</Typography>
                        <TextField
                            disabled
                            fullWidth
                            error={touched.email && Boolean(errors.email)}
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            helperText={touched.email && errors.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" type="submit">Save Account info</Button>
                    </Grid>
                </Grid>
            </form>
    </>
}
