import React, {useEffect, useState} from "react";
import authClient from "../../../../../api/authClient";
import {createRoomPortalInfos, getRoomPortalInfosRoute} from "../../../../../router/api-routes";
import {Room} from "../../../../../models/Room";
import PushRoomPortalDescription from "./PushRoomPortalDescription";
import RoomPortalInfoConfiguration from "./RoomPortalInfoConfiguration";
import {Divider} from "@mui/material";
import PullRoomPortalDescription from "./PullRoomPortalDescription";


interface AdPictureUploadInterface{
    room:Room
}

export interface PortalSubscription{
    portal:{
        id:number
        name:string,
        integration:string,
        logoUrl?:string
        pull:boolean
    }
}

interface PropertyPortalInfo{
    portalSubscription:PortalSubscription
}


export interface RoomPortalInfo{
    id:number,
    roomPortalId:string,
    portalSubscription?: PortalSubscription,
    propertyPortalInfo?: PropertyPortalInfo,
    active: boolean
}

export default function AdPortalsTab({room}:AdPictureUploadInterface){

    const [roomPortalInfos,setRoomPortalInfos] = useState<RoomPortalInfo[]>([])
    const getRoomPortalInfos = (roomId:number) => authClient.get(getRoomPortalInfosRoute(roomId)).then(response => {
        setRoomPortalInfos(response.data["hydra:member"])
    })

    const generateRoomPortalInfo = ()=> authClient.post(createRoomPortalInfos(room.id)).then(()=>getRoomPortalInfos(room.id))

    const pullRoomPortalInfos = roomPortalInfos.filter(roomPortalInfo => {
        const portalSubscription = roomPortalInfo.portalSubscription ?? roomPortalInfo.propertyPortalInfo?.portalSubscription
        return portalSubscription?.portal.pull;
    })

    const pushRoomPortalInfos = roomPortalInfos.filter(roomPortalInfo => {
        const portalSubscription = roomPortalInfo.portalSubscription ?? roomPortalInfo.propertyPortalInfo?.portalSubscription
        return !portalSubscription?.portal.pull;
    })

    useEffect(()=>{
        // @ts-ignore
        getRoomPortalInfos(room.id)
    },[room.id])
    return <>
        <section>
            <h5>Available pull integrations</h5>
            {pullRoomPortalInfos.length > 0 && pullRoomPortalInfos.map(roomPortalInfo => {
                return <React.Fragment key={roomPortalInfo.id}>
                    <PullRoomPortalDescription room={room} roomPortalInfo={roomPortalInfo} getRoomPortalInfos={getRoomPortalInfos} />
                </React.Fragment>
            })}
            <Divider className="my-3"/>
        </section>
        <section>
            <h5>Available pull integrations</h5>
            {pushRoomPortalInfos.length > 0 && pushRoomPortalInfos.map(roomPortalInfo => {
                return <React.Fragment key={roomPortalInfo.id}>
                    <PushRoomPortalDescription room={room} roomPortalInfo={roomPortalInfo} getRoomPortalInfos={getRoomPortalInfos} />
                </React.Fragment>
            })}
            <Divider className="my-3"/>
        </section>

        {roomPortalInfos.length === 0 && <RoomPortalInfoConfiguration generateRoomPortalInfo={generateRoomPortalInfo}/>}

    </>
}

