export const AUTH_ENTRYPOINT = process.env.REACT_APP_AUTH_ENDPOINT;

export const check_login = AUTH_ENTRYPOINT + "/authentication_token"
export const registration = AUTH_ENTRYPOINT + "/register"
export const refreshToken = AUTH_ENTRYPOINT + "/api/token/refresh"


//ROOMS
export const createRoom = AUTH_ENTRYPOINT + `/api/rooms`
export const editRoom = (id) => AUTH_ENTRYPOINT + `/api/rooms/${id}`
export const getRoom = (id) => AUTH_ENTRYPOINT + `/api/rooms/${id}`
export const getRooms = AUTH_ENTRYPOINT + `/api/rooms`
export const savePictures = (id) => AUTH_ENTRYPOINT + `/api/rooms/${id}/save_pictures`
export const savePropertyPictures = (id) => AUTH_ENTRYPOINT + `/api/properties/${id}/save_pictures`
//LISTINGS
export const listings = AUTH_ENTRYPOINT + `/api/resources/listings`

//ENQUIRIES
export const getEnquiries = AUTH_ENTRYPOINT + '/api/enquiries'
export const getEnquiryRoute = (id) => AUTH_ENTRYPOINT + `/api/enquiries/${id}`

//USER
export const getUser = (userId) => AUTH_ENTRYPOINT + `/api/users/${userId}`
export const editUser = (userId) => AUTH_ENTRYPOINT + `/api/users/${userId}`

//COMPANY
export const getCompany = (companyId) => AUTH_ENTRYPOINT + `/api/companies/${companyId}`
export const editCompany = (companyId) => AUTH_ENTRYPOINT + `/api/companies/${companyId}`
export const createCompanySubscriptions = (companyId) => AUTH_ENTRYPOINT + `/api/companies/${companyId}/create_subscriptions`
export const createCompanySubscriptionsByIntegration = (companyId, integration) => AUTH_ENTRYPOINT + `/api/companies/${companyId}/create_subscriptions/${integration}`
export const createRoomPortalInfos = (roomId) => AUTH_ENTRYPOINT + `/api/rooms/${roomId}/generate_portal_infos`

//PROPERTIES
export const createProperty = AUTH_ENTRYPOINT + "/api/properties"
export const getProperty =  (id) =>  AUTH_ENTRYPOINT + `/api/properties/${id}`
export const editProperty =  (id) =>  AUTH_ENTRYPOINT + `/api/properties/${id}`
export const getPropertyRooms =  (id) =>  AUTH_ENTRYPOINT + `/api/properties/${id}/rooms`
export const getPropertyPortalInfos =  (id) =>  AUTH_ENTRYPOINT + `/api/properties/${id}/propertyPortalInfos`
export const getProperties = (companyId)=> AUTH_ENTRYPOINT + `/api/companies/${companyId}/properties`

//ROOMS
export const switchRoomStatus = (id) => AUTH_ENTRYPOINT + `/ads/switch_status/${id}`
export const switchBoostStatus = (id) => AUTH_ENTRYPOINT + `/ads/boost/${id}`
export const switchPropertyAdsStatusRoute = (id) => AUTH_ENTRYPOINT + `/ads/switch_property_status/${id}`
export const switchPropertyAdvertisabilityRoute = (id) => AUTH_ENTRYPOINT + `/ads/switch_property_advertisability/${id}`
export const switchPropertyActiveRoute = (id) => AUTH_ENTRYPOINT + `/ads/switch_property_active/${id}`
export const createSubscription = (redirectUri) => AUTH_ENTRYPOINT + `/api/stripe/create_subscription?redirectUri=${redirectUri}`
export const getRoomPortalInfosRoute =  (id) =>  AUTH_ENTRYPOINT + `/api/rooms/${id}/roomPortalInfos`

//PAYMENTS
export const acceptPaymentRoute = (paymentReferenceId) => AUTH_ENTRYPOINT + `/api/accept-booking/${paymentReferenceId}`
export const denyPaymentRoute = (paymentReferenceId) => AUTH_ENTRYPOINT + `/api/deny-booking/${paymentReferenceId}`

export const getCompanyUsers = (companyId) => AUTH_ENTRYPOINT + `/api/companies/${companyId}/users`
export const createCompanyUser = (companyId) => AUTH_ENTRYPOINT + `/api/companies/${companyId}/users`

//PORTALS
export const getPortals = AUTH_ENTRYPOINT + "/api/portals"

export const getSmooubuPropertiesRoute =  (id) =>  AUTH_ENTRYPOINT + `/api/companies/${id}/smoobu/properties`
