import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/dist/css/splide.min.css';
import React from 'react'
export default function CardImageCarousel({images}){

    return <Splide aria-label="My Favorite Images">
        {images.map(image => <SplideSlide>
            <img style={{backgroundSize:"contain", height:250, borderRadius:10}} width="100%"  src={image.url} alt={image.filename}/>
        </SplideSlide> )}
    </Splide>
}
