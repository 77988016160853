import React from "react";
import {Button, Grid} from "@mui/material";
import {Portal} from "./Portals";
import BackendLogoImage from "../../../../reusable/BackendLogoImage";

interface PortalSubscriptionConfigurationInterface{
    generateSubscription: () => Promise<void>
    portal:Portal
}

export default function PortalSingleSubscriptionConfiguration({portal,generateSubscription}:PortalSubscriptionConfigurationInterface){
    return <Grid container spacing={2}>
        <Grid item xs={6}>
            <div key={portal.id}>
                <BackendLogoImage url={portal.logoUrl}/>
            </div>
        </Grid>
        <Grid item xs={6}>
            <Button onClick={()=>generateSubscription()} variant="outlined">Generate configuration</Button>
        </Grid>



    </Grid>
}
