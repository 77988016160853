import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router";
import authClient, {addQueryParams} from "../../../api/authClient";
import {getEnquiries} from "../../../router/api-routes";
import {Enquiry, EnquiryInterface} from "../../../models/Enquiry";
import EnquiriesTable from "./EnquiriesTable";
import {Button, Card, CardContent, Grid} from "@mui/material";
import {FormElement, FormGeneratorContext, FormGeneratorContextProvider} from "form-generator";
import enquiriesFilterFormDescriptor from "./enquiriesFilterFormType";


export default function Enquiries(){

    const [enquiries, setEnquiries] = useState<Enquiry[]>([]);
    const navigate = useNavigate();
    const [filters,setFilters] = useState({})

    useEffect(()=>{
        authClient
            .get(addQueryParams(getEnquiries,filters))
            .then(response => setEnquiries(response.data["hydra:member"].map((enquiry:EnquiryInterface)  => new Enquiry(enquiry)
        )))
    },[filters])


    return <>
        <Card>
            <CardContent>
                <FormGeneratorContextProvider formDescriptor={enquiriesFilterFormDescriptor} onSubmit={(e) => setFilters(e)}>
                    <FormGeneratorContext.Consumer>
                        {()=>{
                            return <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormElement accessor={"surname"}></FormElement>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormElement accessor={"email"}></FormElement>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="contained" type={"submit"}>Search</Button>
                                </Grid>

                            </Grid>
                        }}
                    </FormGeneratorContext.Consumer>
                </FormGeneratorContextProvider>
            </CardContent>
        </Card>
        <EnquiriesTable enquiries={enquiries}/>
        </>
}
