import {Room} from "../../../../../models/Room";
import {changeRoomVisibility, pushRoom} from "../../../../../api/room/roomApi";
import {Button, Grid} from "@mui/material";
import React from "react";
import {PortalSubscription, RoomPortalInfo} from "./AdPortalsTab";
import BackendLogoImage from "../../../../reusable/BackendLogoImage";

interface RoomPortalDescriptionInterface{
    roomPortalInfo: RoomPortalInfo
    room:Room
    getRoomPortalInfos: (roomId:number) => void
}

export default function PushRoomPortalDescription({roomPortalInfo, room,getRoomPortalInfos}:RoomPortalDescriptionInterface){
    const portalSubscription = roomPortalInfo.portalSubscription ?? roomPortalInfo.propertyPortalInfo?.portalSubscription
    const portalId = roomPortalInfo.roomPortalId;

    const link = getLink(portalSubscription, portalId)

    const pushRoomHandler= () => pushRoom(roomPortalInfo.id).then(()=>{
        getRoomPortalInfos(room.id)
    })

    const changeVisibilityHandler= () => changeRoomVisibility(portalId).then(()=>{
        getRoomPortalInfos(room.id)
    })

    return <div className="my-3">
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <BackendLogoImage url={portalSubscription?.portal.logoUrl}/>
            </Grid>
            <Grid item xs={6}>
                {portalId ? <h6>{roomPortalInfo.active ? "Ad is published" : "Ad is not currently visible"}</h6> : <h6>Room is not mapped</h6>}
                {link && <div><a href={link} target="_blank">Ad link</a></div>}
                {portalId && <Button disabled={!room.active} variant="contained" onClick={()=>{changeVisibilityHandler()}}>{roomPortalInfo.active ? "Hide": "Activate"}</Button>}
                <Button color={portalId ? "error": "primary"} variant="contained" onClick={()=>{pushRoomHandler()}}>{portalId ? "Remove" : "Push"}</Button>
            </Grid>
        </Grid>
    </div>

}

function getLink(portalSubscription:PortalSubscription|undefined, portalId:string){
    if(portalSubscription){
        if(portalSubscription.portal.name === "lofti"){
            return `https://uat.backoffice.lofti.co/en/units/${portalId}/details`
        }
    }
    return null;
}
