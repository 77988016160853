import {Button, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import authClient from "../../../../api/authClient";
import {editRoom, listings} from "../../../../router/api-routes";
import {toast} from "react-toastify";
import editRoomFormDescriptor from "./editRoomFormType";
import {SelectOption} from "../../../../components/GenericSelect/GenericSelect";
import {useNavigate} from "react-router";
import {FormElement, FormGeneratorContext, FormGeneratorContextProvider} from "form-generator";
import {RoomDTO} from "../../../../api/room/dto/RoomDTO";
import 'react-datepicker/dist/react-datepicker.css';

interface SelectItem{
    id: string,
    label:string
}

interface EditRoomInterface{
    room:any
}

export default function EditRoom({room}:EditRoomInterface){

    const [roomTypes, setRoomTypes] = useState<SelectOption[]>([])
    const getRoomTypes = ()=> authClient.post(listings, {resources: {"room_types":[]}})

    useEffect(()=>{getRoomTypes().then(response => setRoomTypes((response.data.room_types).map((roomType:SelectItem) => {
        return {
            label:roomType.label,
            value:parseInt(roomType.id),
            formValue: roomType.id
        }
    })))},[])

    const onSubmit = (values:any) => {
            authClient.patch(editRoom(room.id), new RoomDTO({...values, property:values.property.id, latitude: values.property?.latitude, longitude: values.property?.longitude})  )
                .then(()=>toast.success('Room saved', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
                .catch(()=>toast.error('An error occurred', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
    }

    return <FormGeneratorContextProvider existingValue={room} formDescriptor={editRoomFormDescriptor} onSubmit={onSubmit}>
        <FormGeneratorContext.Consumer>
            {({values,isValid,errors})=>{
                return <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormElement accessor={"title"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"description"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"roomType"} options={roomTypes}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"availableFrom"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"video"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"view360"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"latitude"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"longitude"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"weeklyPrice"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={"contained"} type="submit">Save</Button>
                    </Grid>

                </Grid>
            }}</FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>
}
