import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import authClient from "../../../api/authClient";
import {getProperty} from "../../../router/api-routes";
import {useNavigate, useParams} from "react-router";
import {Box, Card, CircularProgress, Tab, Tabs} from "@mui/material";
import {a11yProps, TabPanel} from "../../../components/Tabs";
import RoomsTab from "./Tabs/RoomsTab";
import {saveProperty} from "../../../api/property/propertyApi";
import {PropertyDTO} from "../../../api/property/dto/PropertyDTO";
import PropertyInfoTab from "./Tabs/PropertyInfoTab";
import CardContent from "@mui/material/CardContent";
import PropertyPortalsTab from "./Tabs/PropertyPortalsTab";
import PropertyPicturesTab from "./Tabs/PropertyPicturesTab";
import PropertyMarketingTab from "./Tabs/PropertyMarketingTab";

export default function EditProperty(){

    const params = useParams();
    const navigate = useNavigate()
    const [value, setValue]= useState(0);

    const [property, setProperty] = useState<PropertyDTO|undefined>()

    const updateProperty = () => authClient.get(getProperty(params.id)).then(response => {
        setProperty(response.data)
    })

    useEffect(()=>{
        // @ts-ignore
        updateProperty()
    },[params])


    const savePropertyHandler = (values:PropertyDTO) => saveProperty(values)

    return <Card>
        <CardContent>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={(event, newValue)=>setValue(newValue)} aria-label="basic tabs example">
                    <Tab label="About the property" {...a11yProps(0)} />
                    <Tab label="Pictures" {...a11yProps(1)} />
                    <Tab label="Rooms" {...a11yProps(2)} />
                    <Tab label="Marketing" {...a11yProps(3)} />
                    {/*<Tab label="Existing flatmates" {...a11yProps(3)} />
                    <Tab label="Preferences For New Flatmates" {...a11yProps(4)} />
                    <Tab label="Your Advert & contact details" {...a11yProps(5)} />*/}
                    <Tab label="Portals" {...a11yProps(4)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {property? <PropertyInfoTab property={property} savePropertyHandler={savePropertyHandler}/>: <CircularProgress/>}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {property? <PropertyPicturesTab property={property} getProperty={updateProperty} />: <CircularProgress/>}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {property? <RoomsTab updateProperty={updateProperty} property={property}/>: <CircularProgress/>}
            </TabPanel>
            <TabPanel value={value} index={3}>
                {property? <PropertyMarketingTab savePropertyHandler={savePropertyHandler} property={property}/>: <CircularProgress/>}
            </TabPanel>
            {/*<TabPanel index={3} value={value}>
                {property ? <ExistingFlatmatesTab savePropertyHandler={savePropertyHandler} property={property}/> : <CircularProgress />}
            </TabPanel>*/}
            <TabPanel index={4} value={value}>
                {property ? <PropertyPortalsTab property={property}/> : <CircularProgress />}
            </TabPanel>
        </CardContent>
    </Card>
}
