import React, {useEffect, useState} from 'react'
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {newAd, newProperty} from "../../../router/frontend-routes";
import {useNavigate} from "react-router";
import authClient from "../../../api/authClient";
import {getProperties} from "../../../router/api-routes";
import PropertiesTable from "./PropertiesTable/PropertiesTable";
import {Property} from "../../../models/Property";
import useCurrentUser from "../../../authentication/useCurrentUser";

export default function PropertiesList(){
    const navigate = useNavigate();
    const [properties, setProperties] = useState<Property[]>([])
    const currentUser = useCurrentUser()

    useEffect(()=>{
        authClient.get(getProperties(currentUser.company)).then(response => setProperties(response.data["hydra:member"]))
    },[])

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <Button variant="outlined" onClick={()=>navigate(newProperty)}>New Property</Button>
        </Grid>
        <Grid item xs={12}>
            <PropertiesTable properties={properties}/>
        </Grid>
    </Grid>
}
