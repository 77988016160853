import UsersTable from "./UsersTable";
import {useEffect, useState} from "react";
import authClient, {addQueryParams} from "../../../api/authClient";
import {getCompanyUsers} from "../../../router/api-routes";
import React from 'react'
import useCurrentUser from "../../../authentication/useCurrentUser";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import {newUser} from "../../../router/frontend-routes";

export interface User {
    id: number,
    name:string,
    surname:string,
    email:string
}

export default function Users(){
    const [users, setUsers] = useState<User[]>([]);
    const currentUser = useCurrentUser()
    const navigate = useNavigate()
    const [filters,setFilters] = useState({})

    useEffect(()=>{
        authClient
            .get(addQueryParams(getCompanyUsers(currentUser.company),filters))
            .then(response => setUsers(response.data["hydra:member"]))
    },[filters])
    return <>
            <Button variant="outlined" className="mb-3" onClick={()=>{navigate(newUser)}} >New</Button>
            <UsersTable users={users}/>
        </>
}
