import React, {useEffect, useState} from 'react'
import {Button, Grid} from "@mui/material";
import authClient from "../../../api/authClient";
import {createRoom, listings} from "../../../router/api-routes";
import * as Yup from 'yup';
import {useNavigate} from "react-router";
import {RoomDTO, RoomDTOInterface} from "../../../api/room/dto/RoomDTO";
import {PropertyDTO} from "../../../api/property/dto/PropertyDTO";
import {SelectOption} from "../../../components/GenericSelect/GenericSelect";
import {FormDescriptor, FormElement, FormElements, FormGeneratorContextProvider} from "form-generator";
import FormGeneratorContext from 'form-generator/dist/form-context/FormGeneratorContext';
import Container from "@mui/material/Container";


interface SelectItem{
    id: string,
    label:string
}

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required').nullable(),
    description: Yup.string().required('Description is required').nullable(),
    roomType: Yup.string().required('Room type is required').nullable(),
    price: Yup.number().required('Price is required').moreThan(0),
    latitude: Yup.number().nullable(),
    longitude: Yup.number().nullable()
});

interface AdCreateInterface{
    property?: PropertyDTO;
    onSave:()=>void
}

const initialValues = {
    title:null,
    description:null,
    roomType: null,
    video: null,
    view360:null,
    availableFrom:null,
    price:0,
    latitude: null,
    longitude: null
}

const elements:FormElements = [
    {
        accessor:"title",
        type:"text",
        Header:"Title"
    },
    {
        accessor:"description",
        type:"wysiwyg",
        Header:"Description"
    },
    {
        accessor:"roomType",
        type:"select",
        Header:"Room Type",
        options:[]
    },
    {
        accessor:"availableFrom",
        type:"date",
        Header:"Available from",
    },
    {
        accessor:"video",
        type:"text",
        Header:"video"
    },
    {
        accessor:"view360",
        type:"text",
        Header:"View 360"
    },
    {
        accessor:"latitude",
        type:"float",
        Header:"Latitude"
    },
    {
        accessor:"longitude",
        type:"float",
        Header:"longitude"
    },
    {
        accessor:"price",
        type:"price",
        Header:"Weekly Price"
    },
]

const formDescriptor = new FormDescriptor({elements,validationSchema,initialValues})

export default function NewRoom({property,onSave}:AdCreateInterface){

    const [roomTypes, setRoomTypes] = useState<SelectOption[]>([])
    const getRoomTypes = ()=> authClient.post(listings, {resources: {"room_types":[]}})

    useEffect(()=>{getRoomTypes().then(response => setRoomTypes((response.data.room_types).map((roomType:SelectItem) => {
        return {
            label:roomType.label,
            value:roomType.id.toString(),
            formValue: roomType.id
        }
    })))},[])


    const onSubmit = (values:RoomDTOInterface) => {
        authClient.post(createRoom, new RoomDTO({...values,property: property?.id, latitude: property?.latitude, longitude: property?.longitude}) ).then((response)=>{
            onSave()
        })
    }


    return <FormGeneratorContextProvider formDescriptor={formDescriptor} onSubmit={onSubmit}>
        <FormGeneratorContext.Consumer>
            {({values})=>{
                return <Container><Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormElement accessor={"title"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"description"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"roomType"} options={roomTypes}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"availableFrom"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"video"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormElement accessor={"view360"}/>
                    </Grid>
                    {values.property ===undefined && <>
                        <Grid item xs={12}>
                            <FormElement accessor={"latitude"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormElement accessor={"longitude"}/>
                        </Grid>
                    </>}
                    <Grid item xs={12}>
                        <FormElement accessor={"price"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={"contained"} type="submit">Save</Button>
                    </Grid>

                </Grid></Container>
            }}
        </FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>

}
