import React from "react";
import Button from "@mui/material/Button";

interface RoomPortalInfoConfigurationInterface{
    generateRoomPortalInfo:()=>Promise<void>
}

export default function RoomPortalInfoConfiguration({generateRoomPortalInfo}:RoomPortalInfoConfigurationInterface){
    return <>
        <p>No room portal infos</p>
        <Button variant="outlined" onClick={()=>{generateRoomPortalInfo()}}>Generate</Button>
    </>
}
