import * as Yup from "yup";
import {
    FormDescriptor,
    FormElement,
    FormElements,
    FormGeneratorContext,
    FormGeneratorContextProvider
} from "form-generator";
import useCurrentUser from "../../../../../authentication/useCurrentUser";
import React, {useEffect, useState} from "react";
import {FileToUpload} from "../../../Ads/Tabs/AdPictureUploadTab";
import authClient, {addQueryParams} from "../../../../../api/authClient";
import {
    createCompanySubscriptions,
    createCompanySubscriptionsByIntegration,
    editCompany,
    getCompany,
    getPortals
} from "../../../../../router/api-routes";
import GetCompanyDTO from "../../../../../models/company/GetCompanyDTO";
import {PatchCompanyDTO} from "../../../../../models/company/PatchCompanyDTO";
import {toast} from "react-toastify";
import {Button, CardHeader, Divider, Grid} from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PortalSubscriptionsConfiguration from "./PortalSubscriptionsConfiguration";
import PortalSingleSubscriptionConfiguration from "./PortalSingleSubscriptionConfiguration";

const validationSchema = Yup.object().shape({
});

const companyFormElements:FormElements = [
    {
        accessor:"portalSubscriptions",
        type:"collection",
        Header:"Subscriptions",
        formElements:[
            {
                Header:"id",
                type:"number",
                accessor:"id"
            },
            {accessor:"active", type:"checkbox", Header:"active"},
            {accessor:"portal", type:"embedded", Header:"Portal",
                formElements:[
                    {accessor:"name", type:"text",Header:"Nome"}
                ],
                initialValues:{name:""}
            },
            {
                accessor:"credentials",
                type:"dictionary",
                Header:"Credentials",

            },
        ],
        initialValues:{
            active:false,
            credentials:[]
        },
        lockList:false
    },

]

const initialValues = {
    portalSubscriptions:[],
}

interface Company{
    portalSubscriptions:PortalSubscription[]
}

export interface Portal{
    id: number,
    name: string
    logoUrl?:string
    pull:boolean
    integration:string
}

interface PortalSubscription{
    portal:Portal
}

const formDescriptor = new FormDescriptor({elements:companyFormElements, initialValues,validationSchema})



export default function Portals(){
    const currentUser = useCurrentUser();
    const [company,setCompany] = useState<Company|undefined>()
    const [portals,setPortals] = useState<Portal[]>([])
    // @ts-ignore
    const getCompanyHandler = ()=> authClient.get(getCompany(currentUser.company)).then(response => setCompany(response.data))
    const getPortalsHandler = ()=> authClient.get(getPortals).then(response => setPortals(response.data["hydra:member"]))

    useEffect(()=>{
        getCompanyHandler()
        getPortalsHandler()
    },[])

    const onSubmit = (values:GetCompanyDTO) => {
            return authClient.patch(editCompany(currentUser.company), PatchCompanyDTO.fromGetCompanyDTO(values, currentUser.company) )
                .then((response)=>{
                    getCompanyHandler()
                    toast.success('Company data saved', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    return response
                })
                .catch((error)=>{
                    toast.error('An error occurred', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    return error
                })
        }

    const generateSubscriptions = ()=> authClient.post(createCompanySubscriptions(currentUser.company)).then(()=>{getCompanyHandler()})

    const generateSubscriptionsByPortalName = (portalName:string)=> authClient.post(createCompanySubscriptionsByIntegration(currentUser.company, portalName)).then(()=>{getCompanyHandler()})

    const pullPortals = portals.filter(portal => portal.pull)
    const pushPortals = portals.filter(portal => !portal.pull)
    const alreadyImplementedPullPortalSubscriptions = company?.portalSubscriptions.filter(portalSubscription => portalSubscription.portal.pull) ?? []
    const alreadyImplementedPullPortals = company?.portalSubscriptions.filter(portalSubscription => portalSubscription.portal.pull).map(portalSub => portalSub.portal.id) ?? []
    const alreadyImplementedPushPortals = company?.portalSubscriptions.filter(portalSubscription => !portalSubscription.portal.pull).map(portalSub => portalSub.portal.id) ?? []

    return <>
        <CardHeader title={"Portal subscriptions"}/>
        <section>
            <h5>Available pull integrations</h5>
            {pullPortals.filter(portal => !alreadyImplementedPullPortals.includes(portal.id) ).map(portal => <PortalSingleSubscriptionConfiguration generateSubscription={() => generateSubscriptionsByPortalName(portal.integration)} portal={portal}/>)}
            <Divider className="my-3"/>
        </section>
        {/*<section>
            <h5>Configured pull integrations</h5>
            {alreadyImplementedPullPortalSubscriptions.map(portalSubscription => <div key={portalSubscription.portal.id}>
                <img height={40} src={`${process.env.REACT_APP_AUTH_ENDPOINT}${portalSubscription.portal.logoUrl}`}/>
            </div>)}
            <Divider className="my-3"/>
        </section>*/}
        <section>
            <h5>Available push integrations</h5>
            {pushPortals.filter(portal => !alreadyImplementedPushPortals.includes(portal.id) ).map(portal =><PortalSingleSubscriptionConfiguration generateSubscription={() => generateSubscriptionsByPortalName(portal.integration)} portal={portal}/>)}
            <Divider className="my-3"/>
        </section>
        <section>
            <h5>Configured integratons</h5>
            <FormGeneratorContextProvider onSubmit={onSubmit}  existingValue={company} formDescriptor={formDescriptor}>
                <FormGeneratorContext.Consumer>
                    {({values})=>{
                        return <>
                            <Grid container className="mt-3" spacing={2}>
                                <Grid item xs={12}>
                                    {values.portalSubscriptions.length >0 && <FormElement accessor={"portalSubscriptions"} removeButton={<></>} addButton={<></>} nestedForm={PortalSubscriptionForm}/>}
                                    {values.portalSubscriptions.length === 0 && <PortalSubscriptionsConfiguration generateSubscriptions={generateSubscriptions}/>}
                                </Grid>
                                <Grid item xs={12}>
                                    {values.portalSubscriptions.length >0 && <Button variant="contained" type="submit">Save portals</Button>}
                                </Grid>
                            </Grid>
                        </>
                    }}
                </FormGeneratorContext.Consumer>
            </FormGeneratorContextProvider>
        </section>
    </>
}

const PortalSubscriptionForm = (what:any) => {
    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
        >
            <FormElement accessor={"portal"} nestedForm={PortalForm}/>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container>
                <Grid item xs={12}>
                    Active: <FormElement accessor={"active"}/>
                </Grid>
                <Grid item xs={12}>
                    <FormElement accessor={"credentials"}/>
                </Grid>
            </Grid>
        </AccordionDetails>
    </Accordion>
}

const PortalForm = () => {
    return <div>
        <FormElement accessor={"name"}/>
    </div>
}
