import * as React from 'react';
import {useContext} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme} from '@mui/material/styles';
import {authProvider} from "./authProvider";
import {FormikValues} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router";
import FormGeneratorContextProvider from "form-generator/dist/form-context/FormGeneratorContextProvider";
import FormGeneratorContext from "form-generator/dist/form-context/FormGeneratorContext";
import {FormElements} from "form-generator/dist/ElementInterface";
import FormElement from "form-generator/dist/form-elements/FormElement";
import {toast} from "react-toastify";
import FormGeneratorThemeContext from "form-generator/dist/form-generator-theme/FormGeneratorThemeContext";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://werooms.com/">
                Werooms
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const signupFormElements:FormElements = [
    {
        accessor:"companyName",
        type:"text",
        Header:"Company name"
    },
    {
        accessor:"email",
        type:"text",
        Header:"Email"
    },
    {
        accessor:"password",
        type:"password",
        Header:"Password"
    },
    {
        accessor:"subscriptionType",
        type:"radio",
        Header:"Subscription Type",
        options:[
            {label:"Subscription", value:"subscription"},
            {label:"Pay as you go", value:"pay_as_you_go"},
        ]
    },
    {
        accessor:"subscriptionPlan",
        type:"radio",
        Header:"Subscription Plan",
        options:[
            {label:"Small", value:"small"},
            {label:"Medium", value:"medium"},
            {label:"Large", value:"large"},
        ]
    },
    {
        accessor:"weroomsPlan",
        type:"checkbox",
        Header:"By clicking this, I agree to subscribe to the Werooms programme ",
    },
    {
        accessor:"bankAccount",
        type:"text",
        Header:"Bank Account"
    },
    {
        accessor:"bic",
        type:"text",
        Header:"BIC / Swift Code"
    }
]

const initialValues = {
    companyName:null,
    email:null,
    password:null,
    subscriptionType:"subscription",
    subscriptionPlan:"small",
    weroomsPlan:false,
    bankAccount:null,
    bic:null
}

const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Type the name of the company").nullable(),
    email: Yup.string().required("Type the email").nullable(),
    password: Yup.string().required("Type a password").min(8,"Minimum 8 characters").nullable()
});

export default function SignUp() {

    const context = useContext(FormGeneratorThemeContext)
    console.log("context",context)
    const navigate = useNavigate();
    const onSubmit = (values:FormikValues) => authProvider.register(values).then(response => navigate("/signin")).catch(error => toast.error("AHIAHIAHI")) //MANCA IL CATCH

    return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img src="/full_logo.svg" alt="logo.svg"/>
                    <FormGeneratorContextProvider elements={signupFormElements} validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit} >
                        <SignupForm/>
                    </FormGeneratorContextProvider>

                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
    );
}

const SignupForm = ()=>{
    const {values} = useContext(FormGeneratorContext)
    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <FormElement accessor={"companyName"}></FormElement>
        </Grid>
        <Grid item xs={12}>
            <FormElement accessor={"email"}></FormElement>
        </Grid>
        <Grid item xs={12}>
            <FormElement accessor={"password"}></FormElement>
        </Grid>
        <Grid item xs={12}>
            <FormElement accessor={"subscriptionType"}></FormElement>
        </Grid>
        {values.subscriptionType === "subscription" && <Grid item xs={12}>
            <FormElement accessor={"subscriptionPlan"}></FormElement>
        </Grid>}
        <Grid item xs={12}>
            <FormElement accessor={"weroomsPlan"}></FormElement>
        </Grid>
        {values.weroomsPlan && <>
            <Grid item xs={12}>
                <FormElement accessor={"bankAccount"}></FormElement>
            </Grid>
            <Grid item xs={12}>
            <FormElement accessor={"bic"}></FormElement>
            </Grid>
        </>}
        <Grid item xs={12} className="mt-3">
            <Button variant={"contained"} fullWidth type={"submit"}>Register</Button>
        </Grid>

    </Grid>
}
