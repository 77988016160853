import React from "react";

interface BackendLogoImageInterface{
    url?:string
}

export default function BackendLogoImage({url}:BackendLogoImageInterface){
    if(url===undefined) return <span>Image not available</span>
    return <img height={40} src={`${process.env.REACT_APP_AUTH_ENDPOINT}${url}`}/>
}
