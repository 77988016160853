import useCurrentUser from "../../../authentication/useCurrentUser";
import React, {useContext, useEffect, useState} from "react";
import authClient from "../../../api/authClient";
import {createSubscription, editCompany, getCompany} from "../../../router/api-routes";
import {toast} from "react-toastify";
import {Button, Card, CardContent, CardHeader, Divider, Grid} from "@mui/material";
import * as Yup from "yup";
import {FileToUpload} from "../Ads/Tabs/AdPictureUploadTab";
import {
    FormDescriptor,
    FormElement,
    FormElements,
    FormGeneratorContext,
    FormGeneratorContextProvider,
} from "form-generator";
import GetCompanyDTO from "../../../models/company/GetCompanyDTO";
import {PatchCompanyDTO} from "../../../models/company/PatchCompanyDTO";
import {Company} from "../../../models/Company";
import {useLocation} from "react-router";

const validationSchema = Yup.object().shape({
    companyName: Yup.string(),
});

const companyFormElements:FormElements = [
    {
        accessor:"companyName",
        type:"text",
        Header:"Company name"
    },
    {
        accessor:"portalSubscriptions",
        type:"collection",
        Header:"Subscriptions",
        formElements:[
            {
                Header:"id",
                type:"number",
                accessor:"id"
            },
            {accessor:"active", type:"checkbox", Header:"active"},
            {accessor:"portal", type:"embedded", Header:"Portal",
                formElements:[
                    {accessor:"name", type:"text",Header:"Nome"}
                ],
                initialValues:{name:""}
            },
            {
                accessor:"credentials",
                type:"dictionary",
                Header:"Credentials",

            },
        ],
        initialValues:{
            active:false,
            credentials:[]
        },
        lockList:false
    },

]

const initialValues = {
    companyName:"",
    portalSubscriptions:[],
}

const formDescriptor = new FormDescriptor({elements:companyFormElements, initialValues,validationSchema})

export const CompanyForm = () => {
    const currentUser = useCurrentUser();
    const [company,setCompany] = useState<Company>()
    // @ts-ignore
    const getCompanyHandler = ()=> authClient.get(getCompany(currentUser.company)).then(response => setCompany(new Company(response.data)))

    useEffect(()=>{
        getCompanyHandler()
    },[])

    const onSubmit = (values:GetCompanyDTO) => {

        return authClient.patch(editCompany(currentUser.company), PatchCompanyDTO.fromGetCompanyDTO(values,currentUser.company) )
            .then((response)=>{
                getCompanyHandler()
                toast.success('Company data saved', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                return response
            })
            .catch((error)=>{
                toast.error('An error occurred', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                return error
            })
    }

    return <FormGeneratorContextProvider onSubmit={onSubmit}  existingValue={company} formDescriptor={formDescriptor}>
        <FormGeneratorContext.Consumer>
            {({values})=>{
                return <>
                    <CardHeader title={company?.companyName} subheader={<p>Plan: {company?.getSubscriptionType()}</p>}/>
                        <Grid container className="mt-3" spacing={2}>
                            {company?.subscriptionType === "subscription" && <Grid item xs={12}>
                                <Divider className="my-3"/>
                                {values.subscriptionActive ? <p>The subscription is active</p> : <SubscriptionPanel/>}
                            </Grid>}

                            <Grid item xs={12}>
                                <Divider className="my-3"/>
                                <FormElement accessor={"companyName"}/>
                                <Button variant="contained" className="my-2" type="submit">Save Company data</Button>
                            </Grid>
                        </Grid>
                </>
            }}
        </FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>
}


function SubscriptionPanel(){
    const location = useLocation();
    const createSubscriptionHandler = () => authClient.post(createSubscription(location.pathname)).then(response => window.location.href = response.data.url).catch(error => toast.error(error.response.data.message))
    return <>
        <p>The subscription is not active</p>
        <Button onClick={createSubscriptionHandler} variant="outlined">Activate subscription</Button>
    </>
}
