import React, {useState} from 'react'
import {Box, Tab, Tabs} from "@mui/material";
import {CompanyForm} from "./CompanyForm";
import {a11yProps, TabPanel} from "../../../components/Tabs";
import UserData from "./Pages/UserData";
import Portals from './Pages/Portals/Portals';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

interface User {
    id: number,
    name?: string,
    surname?: string,
    phoneNumber?: string
}

export default function Account(){

    const [value, setValue]= useState(0);

    return <Card>
        <CardContent>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={(event, newValue)=>setValue(newValue)} aria-label="basic tabs example">
                    <Tab label="Company data" {...a11yProps(0)} />
                    <Tab label="User data" {...a11yProps(1)} />
                    <Tab label="Portals" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <CompanyForm/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UserData/>
            </TabPanel>
            <TabPanel index={2} value={value}>
                <Portals/>
            </TabPanel>
        </CardContent>
    </Card>

}

