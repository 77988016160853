import {adminCompanies} from "../../router/frontend-routes";
import * as React from "react";
import Dashboard from "../../components/Dashboard/Dashboard";
import BusinessIcon from '@mui/icons-material/Business';

const routes = [
    {icon: <BusinessIcon />, text:"Companies", route:adminCompanies}
]


export default function AdminDashboard({content = <></>}:any){
    return <>
        <Dashboard content={content} routes={routes} />
    </>
}
