import {PaymentStatus} from "./PaymentStatus";

export interface EnquiryInterface{
    id:number,
    name: string,
    surname: string,
    email: string,
    phoneNumber:string,
    room: Room,
    startDate?:string,
    endDate?:string,
    createdAt?:string
    paymentStatus?:number
    paymentReferenceId?:string

}

export class Enquiry{
    id:number
    name: string
    surname: string
    email: string
    phoneNumber:string
    room: Room
    createdAt?:string
    startDate?:string
    endDate?:string
    paymentStatus:PaymentStatus
    paymentReferenceId?:string

    constructor({id,name,surname,email,phoneNumber,room, startDate, endDate,paymentReferenceId,paymentStatus,createdAt}:EnquiryInterface) {
        this.id = id;
        this.name = name;
        this.surname = surname;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.room = room
        this.startDate = startDate;
        this.endDate = endDate
        this.paymentReferenceId = paymentReferenceId
        this.paymentStatus = new PaymentStatus({status:paymentStatus})
        this.createdAt = createdAt
    }

    isPending(){
        return this.paymentStatus.status === "Pending";
    }

    isRefused(){
        return this.paymentStatus.status === "Refused";
    }

    isAccepted(){
        return this.paymentStatus.status === "Accepted";
    }
}



interface Room {
    id: number,
    title: string,
}
