import {Button, Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import authClient from "../../../../api/authClient";
import {editRoom, listings} from "../../../../router/api-routes";
import {toast} from "react-toastify";
import editRoomFormDescriptor from "./editRoomFormType";
import {SelectOption} from "../../../../components/GenericSelect/GenericSelect";
import {useNavigate} from "react-router";
import {FormElement, FormGeneratorContext, FormGeneratorContextProvider} from "form-generator";
import {RoomDTO} from "../../../../api/room/dto/RoomDTO";
import 'react-datepicker/dist/react-datepicker.css';
import roomAmenitiesFormDescriptor from "./roomAmenitiesFormType";

interface SelectItem{
    id: string,
    label:string
}

interface EditRoomInterface{
    room:any
}

export default function RoomAmenities({room}:EditRoomInterface){

    const [roomAmenities, setRoomAmenities] = useState<SelectOption[]>([])
    const getRoomAmenities = ()=> authClient.post(listings, {resources: {"room_amenities":[]}})

    useEffect(()=>{getRoomAmenities().then(response => setRoomAmenities((response.data.room_amenities).map((roomType:SelectItem) => {
        return {
            label:roomType.label,
            value:parseInt(roomType.id),
            formValue: roomType.id
        }
    })))},[])

    const onSubmit = (values:any) => {
        console.log("values",values)
            authClient.patch(editRoom(room.id), new RoomDTO({...values, property:values.property.id, latitude: values.property?.latitude, longitude: values.property?.longitude})  )
                .then(()=>toast.success('Room saved', {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
                .catch(()=>toast.error('An error occurred', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }))
    }



    return <FormGeneratorContextProvider existingValue={room} formDescriptor={roomAmenitiesFormDescriptor} onSubmit={onSubmit}>
        <FormGeneratorContext.Consumer>
            {({values,isValid,errors})=>{
                return <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormElement accessor={"amenities"} options={roomAmenities}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant={"contained"} type="submit">Save</Button>
                    </Grid>
                </Grid>
            }}</FormGeneratorContext.Consumer>
    </FormGeneratorContextProvider>
}
