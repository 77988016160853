import React, {useEffect, useState} from 'react'
import {FormDescriptor, FormElements, FormGeneratorContextProvider} from "form-generator";
import authClient from "../../../api/authClient";
import {getUser} from "../../../router/api-routes";
import {useParams} from "react-router";
import {User} from "./Users";
import {Card,CardHeader, CardContent} from "@mui/material";

const elements:FormElements = [
    {accessor:"name", Header:"Name", type:"text"},
    {accessor:"surname", Header:"Surname", type:"text"},
    {accessor:"email", Header:"Email", type:"text"},
]
const initialValues = {
    name:"", surname: "", email:""
}

const formDescriptor = new FormDescriptor({elements, initialValues})

export default function EditUser(){
    const params = useParams()
    const [user, setUser] = useState<User|undefined>()

    useEffect(()=>{
        // @ts-ignore
        authClient.get(getUser(params.id)).then(response => setUser(response.data))
    },[])

    return <Card>
        <CardHeader title="Edit user"/>
        <CardContent>
            <FormGeneratorContextProvider existingValue={user} formDescriptor={formDescriptor}>
            </FormGeneratorContextProvider>
        </CardContent>
    </Card>

}
